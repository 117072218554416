import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native-web";
import { Divider } from "rsuite";
import PopupBackdrop from "../../../../component/Modal/PopupBackDrop";
import Button from "../../../../component/Button/Button";
import { useDialog } from "../../../../component/Modal/Popup";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import {
  getFirestoreDataById,
  getFirestoreDataWithMultiQuery,
  getRealtimeData,
} from "../../../../service/firebase";
import moment from "moment";
import TableAttendanceHistory from "./TableAttendanceHistory";
import { convertMinuteToTimeString } from "../functions/action";

const PopupEditFingerPrint = (props) => {
  const { refId } = props;
  const { onClose, onEnter } = props;
  //property
  const dialog = useDialog();
  const { user } = useSelector((state) => state);
  //state
  const [pageControl, setPageControl] = useState({
    currentPage: 1,
    limitItemsPerPage: 20,
  });
  const [timeAttendanceData, setTimeAttendanceData] = React.useState({});
  const [historyData, setHistoryData] = React.useState([]);

  useEffect(() => {
    if (refId) {
      downloadTimeAttendanceData();
    }
  }, [refId]);

  const downloadTimeAttendanceData = async () => {
    const results = await getFirestoreDataById("TimeAttendance", refId);
    if (!results.status) {
      dialog.alert({
        message: `error downloadTimeAttendanceData ${results.message}`,
      });
      return;
    }
    let data = results.data;

    //หาข้อมูลพนักงาน
    const findEmployeeProfile = await getRealtimeData(
      `Employee/${data?.employeeId || ""}`
    );
    if (!findEmployeeProfile.status) {
      dialog.alert({
        message: `เกิดข้อผิดพลาด : ${findEmployeeProfile.message}`,
      });

      return;
    }
    console.log("findEmployeeqProfile", findEmployeeProfile);
    const employeeInit = {
      employeeProfile: findEmployeeProfile.data,
      firstname: findEmployeeProfile.data.firstname,
      lastname: findEmployeeProfile.data.lastname,
      employeeNumber: findEmployeeProfile.data.employeeNumber,
      startJobDate: findEmployeeProfile.data.startJobDate,
    };
    data = {
      ...data,
      ...employeeInit,
    };

    //หาข้อมูลการลาทั้งหมดของรายการเข้างานวันที่เลือก
    const queries = [["timeAttendanceId", "==", refId]];
    const resultsLeave = await getFirestoreDataWithMultiQuery(
      "TotalLeave",
      queries
    );
    if (!resultsLeave.status) {
      dialog.alert({ message: `เกิดข้อผิดพลาด : ${resultsLeave.message}` });
      return;
    }
    const getUserTransactionProfile = async (userId) => {
      return new Promise(async (resolve) => {
        const user = await getRealtimeData(`User/${userId}`);
        if (user.status) {
          resolve(user.data);
        } else {
          resolve(null);
        }
      });
    };
    //หา log ผู้ทำรายการ
    const findProfileTransaction = resultsLeave.data.map(async (data) => {
      const profile = await getUserTransactionProfile(data.transactionId);
      return {
        ...data,
        transactionProfile: profile,
      };
    });
    const importLeave = await Promise.all(findProfileTransaction);
    data = {
      ...data,
      importLeave,
    };
    console.log("data", data);
    setTimeAttendanceData(data);

    //cal table
    let tableData = [];
    const currentImportList = [];

    for (let x = 0; x <= data.logIndex; x++) {
      console.log("data", data);
      const date = data.createBy[x]?.transactionDate?.toDate() || null;
      const userTransaction = data.createBy[x] || {};
      const manHourString = data.manHourStringLog[x]?.manHourString || "00:00";
      const otTotalMinute = data.otTotalMinuteLog[x]?.otTotalMinute || 0;
      const fingerprint1 = data.fingerprint1Log[x]?.fingerprint || "00:00";
      const fingerprint2 = data.fingerprint2Log[x]?.fingerprint || "00:00";
      const fingerprint3 = data.fingerprint3Log[x]?.fingerprint || "00:00";
      const fingerprint4 = data.fingerprint4Log[x]?.fingerprint || "00:00";
      const fingerprint5 = data.fingerprint5Log[x]?.fingerprint || "00:00";
      const fingerprint6 = data.fingerprint6Log[x]?.fingerprint || "00:00";

      const importLeaveLog = data.importLeaveLog[x] || {};
      console.log("importLeaveLog", importLeaveLog);
      let renderImportLeaveLog = "";

      if (importLeaveLog?.data?.length > 0) {
        importLeaveLog?.data?.forEach((item) => {
          if (item.leaveType === "absentWork") {
            item.titleLabel = "ขาดงาน";
          }
          if (item.transactionType === "initial") {
            currentImportList.push(item);
          } else if (item.transactionType === "remove") {
            let findIndex = currentImportList.findIndex((d) => {
              return d.id === item.id;
            });
            if (findIndex !== -1) {
              currentImportList.splice(findIndex, 1);
            }
          }
          const timeType =
            item.leaveDayType === "full"
              ? "เต็มวัน"
              : `${item.startTime} - ${item.endTime}`;
          renderImportLeaveLog += `${
            item.transactionType === "remove" ? "ลบรายการ" : "เพิ่มรายการ"
          } / ${item.titleLabel} (${timeType})\n`;
        });
      } else {
        renderImportLeaveLog = "-";
      }

      let renderCurrentImportLeave = "";
      if (currentImportList.length > 0) {
        currentImportList.forEach((item) => {
          const timeType =
            item.leaveDayType === "full"
              ? "เต็มวัน"
              : `${item.startTime} - ${item.endTime}`;
          renderCurrentImportLeave += `${item.titleLabel} (${timeType})\n`;
        });
      } else {
        renderCurrentImportLeave = "-";
      }

      tableData.push({
        date: date,
        userTransaction: userTransaction,
        manHourString: manHourString,
        otString: data.processOt
          ? convertMinuteToTimeString(otTotalMinute)
          : "00:00",
        fingerprint1: fingerprint1,
        fingerprint2: fingerprint2,
        fingerprint3: fingerprint3,
        fingerprint4: fingerprint4,
        fingerprint5: fingerprint5,
        fingerprint6: fingerprint6,
        importLeaveText: renderCurrentImportLeave,
        importLeaveLogText: renderImportLeaveLog,
      });
    }
    console.log("tableData", tableData);
    setHistoryData(tableData);
  };

  const renderDate = () => {
    if (!timeAttendanceData) {
      return "-";
    }
    if (!timeAttendanceData.date) {
      return "-";
    }

    return moment(timeAttendanceData.date.toDate()).format("DD/MM/YYYY");
  };

  const renderEmployee = () => {
    if (!timeAttendanceData) {
      return "-";
    }
    if (!timeAttendanceData.employeeProfile) {
      return "-";
    }

    return `${timeAttendanceData.employeeProfile.firstname} ${timeAttendanceData.employeeProfile.lastname} (${timeAttendanceData.employeeProfile.employeeNumber})`;
  };

  return (
    <PopupBackdrop>
      <View style={Styles.container}>
        <Header
          onEnter={() => {}}
          onClose={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
        <Text style={Styles.subTitle}>วันที่วางนิ้ว : {renderDate()}</Text>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Text>ข้อมูลพนักงาน : {renderEmployee()}</Text>
          </Grid>
        </Grid>
        <Line />
        <View style={{ flex: 1 }}>
          <TableAttendanceHistory
            tableData={historyData}
            pageControl={pageControl}
            onSelectPage={(data) => {
              setPageControl({
                ...pageControl,
                ...data,
              });
            }}
          />
        </View>
      </View>
    </PopupBackdrop>
  );
};

const Header = (props) => {
  const { onClose, onEnter } = props;
  return (
    <View style={{ width: "100%" }}>
      <View style={{ flexDirection: "row" }}>
        <Text style={Styles.title}>ประวัติการแก้ไข</Text>
        <Button
          style={{ width: 100 }}
          buttonText={"ปิด"}
          color={"secondary"}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
      </View>
      <Divider />
    </View>
  );
};

export default PopupEditFingerPrint;

const Line = (props) => {
  return (
    <View
      style={{
        width: "100%",
        height: 1,
        backgroundColor: "#ebebeb",
        marginTop: 20,
        marginBottom: 20,
      }}
    />
  );
};

const Styles = {
  container: {
    width: "90vw",
    height: "90vh",
    maxWidth: 1200,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
    zIndex: 10,
  },
  content: {
    flex: 1,
    zIndex: 0,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    flex: 1,
  },
  subTitle: {
    fontSize: 17,
    fontWeight: "bold",
    marginBottom: 10,
  },
  menuContainer: {
    marginTop: 10,
    flexDirection: "row",
    alignItems: "flex-start",
  },
  spaceMenu: {
    width: 15,
  },
};
