import React, { useState, useEffect } from "react";
import moment from "moment";
import { View } from "react-native-web";
//component
import DayBox from "./DayBox";
moment.locale("en"); // ตั้งค่าใช้ภาษาไทย

const BuildDayBox = (props) => {
  const { currentDate, callback } = props;
  const [selectNumber, setSelectNumber] = useState(moment(currentDate).date());
  const [content, setContent] = useState([]);

  useEffect(() => {
    const date = moment(currentDate);
    const startOfMonth = date.startOf("month").day();
    const endOfMonth = date.endOf("month").day();
    const totalDaysInMonth = date.daysInMonth();
    const totalRow = Math.ceil(
      (totalDaysInMonth + startOfMonth + (6 - endOfMonth)) / 7
    );

    const prevMonth = moment(date).subtract(1, "month");
    const dayBeforeMonth = [];
    for (
      let x = prevMonth.daysInMonth() - startOfMonth + 1;
      x <= prevMonth.daysInMonth();
      x++
    ) {
      dayBeforeMonth.push({ day: x, isActive: false, isValid: false });
    }

    const dayAfterMonth = [];
    for (let x = 1; x <= 6 - endOfMonth; x++) {
      dayAfterMonth.push({ day: x, isActive: false, isValid: false });
    }

    const newContent = [<View style={{ marginTop: 10 }} key="marginTop" />];
    let currentDay = 0;

    for (let x = 1; x <= totalRow; x++) {
      const data = [];
      for (let i = 0; i < 7; i++) {
        if (x === 1 && i < startOfMonth) {
          data.push(dayBeforeMonth[i] || null);
        } else if (currentDay < totalDaysInMonth) {
          currentDay += 1;
          data.push({ day: currentDay, isActive: true, isValid: true });
        } else {
          const dayAfterIndex = currentDay - totalDaysInMonth;
          data.push(dayAfterMonth[dayAfterIndex] || null);
          currentDay += 1;
        }
      }
      newContent.push(
        <View style={Styles.dayBoxLineContainer} key={"dayBoxContainer" + x}>
          {data.map((item, index) =>
            item ? (
              <DayBox
                key={"daybox_" + index}
                currentDate={currentDate}
                selectNumber={selectNumber}
                day={item.day}
                isActive={item.isActive}
                isValid={item.isValid}
                onPress={(response) => {
                  callback && callback(response);
                  setSelectNumber(item.day);
                }}
              />
            ) : (
              <DayBox key={"daybox_" + index} />
            )
          )}
        </View>
      );
    }
    setContent(newContent);
  }, [currentDate, selectNumber, callback]);

  return content;
};

const Styles = {
  icon: {
    fontSize: 20,
    color: "#4652D1",
  },
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  selectBox: {
    width: "100%",
    flexDirection: "row",
  },
  dayBoxLineContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default BuildDayBox;
