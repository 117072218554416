import React, { useState, useRef } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
//components
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
//images
import emptyProduct from "../../../../Asset/Images/emptyProduct.png";
//functions
import TextField from "../../../../component/Textfield/TextField";
import Button from "../../../../component/Button/Button";
import PageButtonNavigateV2 from "../../../../component/Button/PageButtonNavigateV2";
import { IoTimer } from "react-icons/io5";
import CustomCheckbox from "../../../../component/checkbox/CheckBox";
import { IoIosSave } from "react-icons/io";
import { useDialog } from "../../../../component/Modal/Popup";

const TableViewLeaveRecal = (props) => {
  const { tableData = [], pageControl = {}, showPageSelect = true } = props;
  const { onChange, onDeleteSelectAll, onSelectPage } = props;
  const tableContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const dialog = useDialog();

  const handleScrollLeft = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft -= 100;
      setScrollPosition(tableContainerRef.current.scrollLeft);
    }
  };

  const handleScrollRight = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft += 100;
      setScrollPosition(tableContainerRef.current.scrollLeft);
    }
  };

  const handleChangeTime = (values) => {
    let value = values.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    // Validate first digit
    if (value.length >= 1) {
      const firstDigit = parseInt(value[0], 10);
      if (firstDigit > 2) {
        value = ""; // Remove first digit if greater than 2
      }
    }

    // Validate and remove excess digits for HH
    if (value.length >= 2) {
      const hh = parseInt(value.slice(0, 2), 10);
      if (hh > 23) {
        value = value.slice(0, 1); // Remove excess digits
      }
    }

    // Automatically add ':' after 2 digits
    if (value.length >= 3) {
      value = value.slice(0, 2) + ":" + value.slice(2, 4);
    }

    // Validate first minute digit
    const parts = value.split(":");
    if (parts.length === 2 && parts[1].length > 0) {
      const firstMinuteDigit = parseInt(parts[1][0], 10);
      if (firstMinuteDigit > 5) {
        parts[1] = ""; // Remove excess digit
        value = parts[0] + ":";
      }
    }

    // Validate and remove excess digits for mm
    if (parts.length === 2) {
      const mm = parseInt(parts[1], 10);
      if (mm > 59) {
        value = parts[0] + ":" + parts[1].slice(0, 1); // Remove excess digits
      }
    }

    // Limit length to 5 characters
    if (value.length > 5) {
      value = value.slice(0, 5);
    }

    return value;
  };

  const handleDeleteSelectAll = () => {
    const allSelect = tableData?.filter((d) => {
      return d.isSelect;
    });
    if (onDeleteSelectAll) {
      onDeleteSelectAll(allSelect);
    }
  };

  const filterDataByPage = (data, currentPage, limitItemsPerPage) => {
    const startIndex = (currentPage - 1) * limitItemsPerPage;
    const endIndex = startIndex + limitItemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const RenderOptionHeader = () => {
    if (!tableData) {
      return null;
    }
    const checkAllSelect = tableData?.filter((d) => {
      return d.isSelect;
    });
    if (checkAllSelect.length > 0) {
      return (
        <Grid container spacing={2} style={{ alignItems: "center" }}>
          <Grid item xs={12} md={6}>
            <Text>{`รายการที่เลือกทั้งหมด ${checkAllSelect.length} รายการ`}</Text>
          </Grid>
          <Grid item xs={12} md={3}>
            {/* <Button
              buttonText={"บันทึกรายการที่เลือก"}
              color={"primary"}
              style={{ height: 40 }}
              onClick={() => {
                handleDeleteSelectAll();
              }}
            /> */}
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              buttonText={"ลบรายการที่เลือก"}
              color={"secondary"}
              style={{ height: 40 }}
              onClick={() => {
                handleDeleteSelectAll();
              }}
            />
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      {tableData.length > 0 ? (
        <View
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                handleScrollLeft();
              }}
            >
              <FaArrowAltCircleLeft style={{ fontSize: 30 }} />
            </TouchableOpacity>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <RenderOptionHeader />
            </View>
            <TouchableOpacity
              onPress={() => {
                handleScrollRight();
              }}
            >
              <FaArrowAltCircleRight style={{ fontSize: 30 }} />
            </TouchableOpacity>
          </View>
          <TableContainer ref={tableContainerRef} sx={styles.tableContainer}>
            <Table sx={styles.table}>
              <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
                <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
                  <TableCell
                    sx={{
                      ...styles.headerCell2,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    บันทึก
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles.headerCell2,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Man-hour
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles.headerCell2,
                      fontWeight: "bold",
                      fontSize: 18,
                      width: 100,
                    }}
                  >
                    Overtime
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell2 }}>
                    การวางนิ้ว 1
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell2 }}>
                    การวางนิ้ว 2
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell2 }}>
                    การวางนิ้ว 3
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell2 }}>
                    การวางนิ้ว 4
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell2 }}>
                    การวางนิ้ว 5
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell2 }}>
                    การวางนิ้ว 6
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterDataByPage(
                  tableData,
                  pageControl.currentPage,
                  pageControl.limitItemsPerPage
                ).map((row, index) => {
                  if (Number(row.day) === 7) {
                    console.log("HEE", row);
                  }
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        ...styles.tableRow,
                        backgroundColor:
                          index % 2 === 0
                            ? "#D8DDF8"
                            : "rgba(255, 255, 255, 1)",
                      }}
                    >
                      <TableCell sx={{ ...styles.tableCell }}>
                        <View style={{ flexDirection: "row" }}>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <CustomCheckbox
                              value={row.processManHour}
                              onCheck={(value) => {
                                if (onChange) {
                                  onChange({
                                    ...row,
                                    processManHour: value,
                                  });
                                }
                              }}
                            />
                            <TouchableOpacity
                              onPress={() => {
                                dialog.alert({
                                  title: "คำอธิบาย",
                                  message: "ใช้คำนวน ManHour",
                                });
                              }}
                            >
                              <IoIosSave
                                style={{
                                  marginLeft: 5,
                                  fontSize: 20,
                                  color: "#4652D1",
                                  //#4652D1
                                }}
                              />
                            </TouchableOpacity>
                          </View>
                          <View style={{ width: 10 }} />
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <CustomCheckbox
                              value={row.processOt}
                              onCheck={(value) => {
                                if (row.disableOt) {
                                  dialog.alert({
                                    message:
                                      "ไม่สามารถคำนวน OT ได้ เนื่องจากมี ค่าตำแหน่ง >= 5,000 บาท",
                                  });
                                  return;
                                } else {
                                  if (onChange) {
                                    onChange({
                                      ...row,
                                      processOt: value,
                                    });
                                  }
                                }
                              }}
                            />
                            <TouchableOpacity
                              onPress={() => {
                                dialog.alert({
                                  title: "คำอธิบาย",
                                  message: "ใช้คำนวน OT",
                                });
                              }}
                            >
                              <IoTimer
                                style={{
                                  marginLeft: 5,
                                  fontSize: 20,
                                  color: "#4652D1",
                                }}
                              />
                            </TouchableOpacity>
                          </View>
                        </View>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View stytles={styles.fingerprintView}>
                          <TextField
                            placeholder={"Man-hour"}
                            value={row.manHourString ? row.manHourString : ""}
                            disable={true}
                          />
                        </View>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View stytles={{ ...styles.fingerprintView }}>
                          <TextField
                            placeholder={"Overtime"}
                            value={row?.otString || "-"}
                            disable={true}
                          />
                        </View>
                      </TableCell>

                      <TableCell sx={styles.tableCell}>
                        <View stytles={styles.fingerprintView}>
                          <TextField
                            value={
                              row.fingerprint1
                                ? handleChangeTime(row.fingerprint1)
                                : ""
                            }
                            placeholder={"00:00"}
                            onChangeText={(values) => {
                              const value = handleChangeTime(values);
                              if (onChange) {
                                onChange({
                                  ...row,
                                  fingerprint1: value,
                                });
                              }
                            }}
                          />
                        </View>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View stytles={styles.fingerprintView}>
                          <TextField
                            placeholder={"00:00"}
                            maxLength={5}
                            value={
                              row.fingerprint2
                                ? handleChangeTime(row.fingerprint2)
                                : ""
                            }
                            onChangeText={(values) => {
                              const value = handleChangeTime(values);
                              if (onChange) {
                                onChange({
                                  ...row,
                                  fingerprint2: value,
                                });
                              }
                            }}
                          />
                        </View>
                      </TableCell>

                      <TableCell sx={styles.tableCell}>
                        <View stytles={styles.fingerprintView}>
                          <TextField
                            placeholder={"00:00"}
                            value={
                              row.fingerprint3
                                ? handleChangeTime(row.fingerprint3)
                                : ""
                            }
                            onChangeText={(values) => {
                              const value = handleChangeTime(values);
                              if (onChange) {
                                onChange({
                                  ...row,
                                  fingerprint3: value,
                                });
                              }
                            }}
                          />
                        </View>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View stytles={styles.fingerprintView}>
                          <TextField
                            placeholder={"00:00"}
                            value={
                              row.fingerprint4
                                ? handleChangeTime(row.fingerprint4)
                                : ""
                            }
                            onChangeText={(values) => {
                              const value = handleChangeTime(values);
                              if (onChange) {
                                onChange({
                                  ...row,
                                  fingerprint4: value,
                                });
                              }
                            }}
                          />
                        </View>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <TextField
                          placeholder={"00:00"}
                          value={
                            row.fingerprint5
                              ? handleChangeTime(row.fingerprint5)
                              : ""
                          }
                          onChangeText={(values) => {
                            const value = handleChangeTime(values);
                            if (onChange) {
                              onChange({
                                ...row,
                                fingerprint5: value,
                              });
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View stytles={styles.fingerprintView}>
                          <TextField
                            placeholder={"00:00"}
                            value={
                              row.fingerprint6
                                ? handleChangeTime(row.fingerprint6)
                                : ""
                            }
                            onChangeText={(values) => {
                              const value = handleChangeTime(values);
                              if (onChange) {
                                onChange({
                                  ...row,
                                  fingerprint6: value,
                                });
                              }
                            }}
                          />
                        </View>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {showPageSelect && (
            <PageButtonNavigateV2
              totalCount={tableData.length}
              limitItemsPerPage={pageControl.limitItemsPerPage}
              currentPage={pageControl.currentPage}
              onSelectPage={(page) => {
                onSelectPage &&
                  onSelectPage({ ...pageControl, currentPage: page });
              }}
              onChangeLimitPerPage={(value) => {
                onSelectPage({ ...pageControl, limitItemsPerPage: value });
              }}
            />
          )}
        </View>
      ) : (
        <EmptyList />
      )}
    </React.Fragment>
  );
};

const OptionButtons = (props) => {
  const { title, disabled = false } = props;
  const { onPress } = props;
  return (
    <TouchableOpacity
      style={{ justifyContent: "center", alignItems: "center" }}
      activeOpacity={disabled ? 1 : 0.8}
      onPress={() => {
        if (!disabled) {
          onPress && onPress();
        }
      }}
    >
      {props.children}
      <Text
        style={{
          fontSize: 12,
          lineHeight: 12,
          color: disabled ? "#9C9C9C" : "#000000",
          marginTop: 5,
          textAlign: "center",
        }}
      >
        {title || ""}
      </Text>
    </TouchableOpacity>
  );
};

const EmptyList = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "5%",
      }}
    >
      <img src={emptyProduct} style={{ width: 120 }} alt={"emptyProduct"} />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        ยังไม่มีรายการ
      </Text>
    </View>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    overflowX: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 1000,
  },
  tableRow: {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: "rgba(255, 255, 255, 1)",
    // },
    // "&:nth-of-type(even)": {
    //   backgroundColor: "#D8DDF8",
    // },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "#4652D1",
    whiteSpace: "nowrap",
    fontSize: 17,
    fontWeight: "bold",
  },
  headerCell2: {
    color: "#FF0000",
    whiteSpace: "nowrap",
    fontSize: 17,
    fontWeight: "bold",
  },
  fingerprintView: { height: "100%", width: 75 },
};

export default TableViewLeaveRecal;
