import React from "react";
import { Text, TouchableOpacity } from "react-native-web";

const PageButton = (props) => {
  const { label, isSelect, style } = props;
  const { onClick } = props;
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={{
        ...(isSelect ? Styles.selectButton : Styles.defaultButton),
        ...style,
      }}
      onPress={() => {
        onClick && onClick();
      }}
    >
      <Text style={{ ...(isSelect ? Styles.selectText : Styles.text) }}>
        {label ? label : ""}
      </Text>
    </TouchableOpacity>
  );
};

const Styles = {
  defaultButton: {
    backgroundColor: "rgba(0,0,0,0)",
    borderColor: "#D5D7DD",
    borderWidth: 1,
    minWidth: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    marginRight: 5,
    paddingLeft: 4,
    paddingRight: 4,
  },
  selectButton: {
    backgroundColor: "#4652D1",
    borderWidth: 0,
    minWidth: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    marginRight: 5,
    paddingLeft: 4,
    paddingRight: 4,
  },
  text: {
    fontSize: 14,
    color: "#696D79",
  },
  selectText: {
    fontSize: 14,
    color: "#FFFFFF",
  },
};

export default PageButton;
