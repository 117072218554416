import React, { useMemo, useRef, useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { View, Text, ScrollView, TouchableOpacity } from "react-native-web";
import Button from "../Button/Button";
function TimePickerContainer(props) {
  const { onSelect, onClickOutside } = props;
  const [hours, setHours] = useState([]);
  const [minutes, setMinutes] = useState([]);

  const [selectHour, setSelectHour] = useState("0");
  const [selectMinute, setSelectMinute] = useState("0");

  const scrollViewHour = useRef(null);
  const scrollViewMinute = useRef(null);

  const componentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [componentRef]);

  useMemo(() => {
    let initHours = [];
    let initMinutes = [];
    for (let x = 0; x < 60; x++) {
      if (x < 24) {
        initHours.push(x.toString().padStart(2, "0"));
      }
      initMinutes.push(x.toString().padStart(2, "0"));
    }
    setHours(initHours);
    setMinutes(initMinutes);
  }, []);

  return (
    <View style={Styles.container} ref={componentRef}>
      <View style={Styles.landscapeContainer}>
        <View style={Styles.boxHalf}>
          <Text>ชั่วโมง</Text>
          <ScrollView
            ref={scrollViewHour}
            showsVerticalScrollIndicator={false}
            style={Styles.scrollview}
          >
            <View style={Styles.scrollBox}>
              {hours.map((value, index) => {
                return (
                  <TouchableOpacity
                    key={"timePickerHour_" + value}
                    style={{
                      ...Styles.button,
                      backgroundColor: selectHour === value ? "#FFD7D7" : "",
                    }}
                    onPress={() => {
                      setSelectHour(value);
                      scrollViewHour.current.scrollTo({
                        x: 0,
                        y: 56 * index,
                        animated: true,
                      });
                    }}
                  >
                    <Typography style={Styles.textButton}>{value}</Typography>
                  </TouchableOpacity>
                );
              })}
            </View>
          </ScrollView>
        </View>
        <View style={{ maxWidth: 1, flex: 1, backgroundColor: "#EBEBEB" }} />
        <View style={Styles.boxHalf}>
          <Text>นาที</Text>
          <ScrollView
            ref={scrollViewMinute}
            showsVerticalScrollIndicator={false}
            style={Styles.scrollview}
          >
            <View style={Styles.scrollBox}>
              {minutes.map((value, index) => {
                return (
                  <TouchableOpacity
                    key={"timePickerMinute_" + value}
                    style={{
                      ...Styles.button,
                      backgroundColor: selectMinute === value ? "#FFD7D7" : "",
                    }}
                    onPress={() => {
                      setSelectMinute(value);
                      scrollViewMinute.current.scrollTo({
                        x: 0,
                        y: 56 * index,
                        animated: true,
                      });
                    }}
                  >
                    <Typography style={Styles.textButton}>{value}</Typography>
                  </TouchableOpacity>
                );
              })}
            </View>
          </ScrollView>
        </View>
      </View>
      <Button
        buttonText={"ตกลง"}
        color={"primary"}
        style={{ marginTop: 10 }}
        onClick={() => {
          onSelect && onSelect({ hour: selectHour, minute: selectMinute });
        }}
      />
    </View>
  );
}

const Styles = {
  container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    maxHeight: 300,
    borderRadius: 20,
    padding: 10,
  },
  landscapeContainer: {
    width: "100%",
    flexDirection: "row",
    flex: 1,
  },
  boxHalf: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  scrollview: {
    width: "100%",
  },
  scrollBox: {
    width: "100%",
  },
  button: {
    flex: 1,
    height: 30,
    margin: 8,
    padding: 8,
    justifyContent: "center",
    borderRadius: 10,
  },
  textButton: {
    textAlign: "center",
  },
};
export default TimePickerContainer;
