import React, { useState, useEffect } from "react";
import { TouchableOpacity, Text, View } from "react-native-web";
import CalendarRange from "../CalendarV2/CalendarRange";
//Icons
import calendarIcon from "../../Asset/Images/components/calendar.png";
import moment from "moment-timezone";
import { IoCloseCircle } from "react-icons/io5";

const DatePickerRange = (props) => {
  const {
    startDate,
    endDate,
    placeholder,
    styles,
    disable,
    isTop = false,
    clearButton = false,
  } = props;
  const { onChange, onChangeOut, onClear } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [currentStartDate, setCurrentStartDate] = useState();
  const [currentEndDate, setCurrentEndDate] = useState();
  useEffect(() => {
    if (startDate) {
      setCurrentStartDate(startDate);
    }
    if (endDate && startDate) {
      if (!endDate.isBefore(startDate)) {
        setCurrentEndDate(endDate);
      }
    }
    if (!startDate && !endDate) {
      setCurrentStartDate(null);
      setCurrentEndDate(null);
    }
  }, [startDate, endDate]);

  return (
    <React.Fragment>
      <View style={{ zIndex: 99999 }}>
        <TouchableOpacity
          activeOpacity={disable ? 1 : 0.7}
          onPress={() => {
            if (disable) {
              return;
            }
            setIsPopoverOpen(true);
          }}
          style={{ ...Styles.button, ...styles }}
        >
          <Text
            style={{
              fontSize: 16,
              color:
                currentStartDate && currentEndDate
                  ? "#0F1011"
                  : "rgba(138, 145, 161, 1)",
            }}
          >
            {currentStartDate && currentEndDate
              ? `${moment(currentStartDate).format("DD/MM/YYYY")} - ${moment(
                  currentEndDate
                ).format("DD/MM/YYYY")}`
              : placeholder}
          </Text>
          <View style={{ flex: 1 }} />
          {clearButton && startDate && endDate ? (
            <TouchableOpacity
              style={{ marginRight: 4 }}
              onPress={() => {
                onClear && onClear();
              }}
            >
              <IoCloseCircle style={{ fontSize: 20, color: "#9c9c9c" }} />
            </TouchableOpacity>
          ) : null}
          <img
            src={calendarIcon}
            style={{ width: 20, height: 20 }}
            alt={"calendarIcon"}
          />
        </TouchableOpacity>
        {isPopoverOpen ? (
          <View style={{ ...Styles.container, top: isTop ? -330 : 60 }}>
            <CalendarRange
              startDate={currentStartDate}
              endDate={currentEndDate}
              clearButton={clearButton}
              onSelect={(values) => {
                if (!values.startDate || !values.endDate) {
                  return;
                }
                setCurrentStartDate(values.startDate);
                setCurrentEndDate(values.endDate);
                setIsPopoverOpen(false);
                onChange && onChange(values);
              }}
              onClickOutside={() => {
                setIsPopoverOpen(false);
              }}
              onSelectStartDate={(values) => {
                setCurrentStartDate(values);
              }}
              onSelectEndDate={(values) => {
                setCurrentEndDate(values);
                if (!currentStartDate) {
                  return;
                }
                onChange &&
                  onChange({ startDate: currentStartDate, endDate: values });
              }}
            />
          </View>
        ) : null}
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    width: "100%",
    maxWidth: 320,
    maxHeight: 360,
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    position: "absolute",
    right: 0,
    top: -330,
    zIndex: 10000,
    overflow: "hidden",
  },
  button: {
    flexDirection: "row",
    width: 300,
    height: 50,
    borderWidth: 1,
    borderColor: "#D5D7DD",
    borderRadius: 8,
    alignItems: "center",
    padding: 16,
  },
};

export default DatePickerRange;
