import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
export default function CustomCheckbox(props) {
  const { color, value, borderColor = "rgba(213, 215, 221, 1)" } = props;
  const { onCheck } = props;

  const theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: borderColor, // Customize the color here
            "&.Mui-checked": {
              color: color ? color : "#4652D1", // Customize the checked color here
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Checkbox
        checked={value}
        sx={{
          width: "24px",
          height: "24px",
        }}
        onChange={(event) => {
          onCheck && onCheck(event.target.checked);
        }}
      />
    </ThemeProvider>
  );
}
