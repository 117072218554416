import React, { useState, useEffect } from "react";
import { Container } from "shards-react";
import { Grid } from "@mui/material";
import { Text, TouchableOpacity, View } from "react-native-web";
import Button from "../../../component/Button/Button";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import DatePickerRange from "../../../component/CalendarV2/DatePickerRange";
import moment, { max } from "moment";
import Dropdown from "../../../component/Dropdown/Dropdown";
import {
  getBranch,
  getEnterprise,
} from "../../../function/employee/employeeAction";
import { useDialog } from "../../../component/Modal/Popup";
import TableAttendanceView from "./components/TableAttendanceView";
import {
  addFirestoreMultiDataTransaction,
  currentTimestampFirestore,
  firestore,
  getFirestoreData,
  getFirestoreDataWithMultiQuery,
  updateFirestoreData,
  updateFirestoreDataTransaction,
  updateFirestoreMultiDataTransaction,
} from "../../../service/firebase";
import {
  addDoc,
  collection,
  doc,
  runTransaction,
  Timestamp,
} from "firebase/firestore";
import PopupImportLeaveView from "./components/PopupImportLeaveView";
import PopupEditFingerPrint from "./components/PopupEditFingerPrint";
import { convertImportLeavePattern } from "./functions/action";
import { validatesWarningAttendance } from "./functions/validates";
import storeFunction from "./functions/storeFunction";

const ManageTimeAttendance = (props) => {
  const { onBack } = props;
  const navigate = useNavigate();
  const dialog = useDialog();

  //state
  const [pageControl, setPageControl] = useState({
    currentPage: 1,
    limitItemsPerPage: 20,
  });
  const [list, setList] = useState([]);
  const [enterpriseList, setEnterpriseList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [searchData, setSearchData] = useState({
    startDate: moment(),
    endDate: moment(),
    enterpriseId: "",
    branchId: "",
  });
  //state popup
  //รายการลา
  const [attendanceLeaveList, setAttendanceLeaveList] = useState([]);
  const [showPopupImportLeaveView, setShowPopupImportLeaveView] =
    useState(false);
  const [popupLeaveViewId, setPopupLeaveViewId] = useState(null);
  //แจ้งเตือน
  const [showPopupAlert, setShowPopupAlert] = useState(false);
  const [popupAlertId, setPopupAlertId] = useState(null);
  //รายการแก้ไข
  const [showPopupEdit, setShowPopupEdit] = useState(false);
  const [popupEditId, setPopupEditId] = useState(null);

  useEffect(() => {
    initEnterprise();
  }, []);

  useEffect(() => {
    if (searchData.enterpriseId) {
      const downloadBranch = async () => {
        const branchs = await getBranch(searchData.enterpriseId);
        if (branchs.status) {
          setBranchList([
            { label: "ทั้งหมด", value: "ทั้งหมด" },
            ...branchs.data,
          ]);

          setSearchData({
            ...searchData,
            branchId: "ทั้งหมด",
          });
        }
      };
      downloadBranch();
      // downloadEmployeeInBranch(); // ไม่ต้องใช้เพราะเรียกใน useEffect ข้างล่าง กรณีไม่ใช้ สาขา
    }
  }, [searchData.enterpriseId]);

  useEffect(() => {
    if (
      searchData?.startDate &&
      searchData?.endDate &&
      searchData?.enterpriseId &&
      searchData?.branchId
    ) {
      downloadData();
    }
  }, [searchData]);

  useEffect(() => {
    if (searchData?.enterpriseId && searchData?.branchId) {
      downloadAttendanceLeaveList();
    }
  }, [searchData?.enterpriseId, searchData?.branchId]);

  const initEnterprise = async () => {
    const enterprises = await getEnterprise();
    if (!enterprises.status) {
      await dialog.alert({ message: enterprises.message });
      return;
    }
    setEnterpriseList(enterprises.data);

    setSearchData({
      ...searchData,
      enterpriseId: enterprises.data[0].value,
    });
  };

  const downloadAttendanceLeaveList = async () => {
    //รายการลาทั้งหมด
    const queries = [
      ["active", "==", "Y"],
      ["enterpriseList", "array-contains", searchData.enterpriseId],
    ];
    const attendanceList = await getFirestoreDataWithMultiQuery(
      "AttendanceSettingLeave",
      queries
    );
    if (!attendanceList.status) {
      await dialog.alert({ message: attendanceList.message });
      return;
    }

    const filterInBranch = attendanceList.data.filter((d) => {
      if (searchData.branchId === "ทั้งหมด") {
        return d;
      } else {
        return d.branchList.includes(searchData.branchId);
      }
    });
    const getAttendanceLabel = await getFirestoreData("AttendanceSettingLabel");
    if (!getAttendanceLabel.status) {
      await dialog.alert({ message: getAttendanceLabel.message });
      return;
    }

    let init = [];
    filterInBranch.forEach((d) => {
      let findLabel = getAttendanceLabel.data.find((l) => {
        return l.id === d.title;
      });
      init.push({ ...d, titleLabel: findLabel?.label });
    });
    setAttendanceLeaveList(init);
  };

  const downloadData = async () => {
    // สร้าง queries สำหรับ Firestore โดยใช้ช่วงวันที่เดียวกัน (ไม่สนใจเวลา)
    const startOfDay = moment.utc(searchData.startDate).startOf("day").toDate();
    const endOfDay = moment.utc(searchData.endDate).endOf("day").toDate();

    const queries = [
      ["enterpriseId", "==", searchData.enterpriseId],
      ["date", ">=", Timestamp.fromDate(startOfDay)],
      ["date", "<=", Timestamp.fromDate(endOfDay)],
      ["status", "==", "Y"],
    ];

    if (searchData.branchId !== "ทั้งหมด") {
      queries.push(["branchId", "==", searchData.branchId]);
    }
    dialog.loading();
    const results = await getFirestoreDataWithMultiQuery(
      `TimeAttendance`,
      queries
    );
    if (!results.status) {
      await dialog.alert({ message: results.message });
      return;
    }

    let lists = [];
    //กรณีไม่นำ Manhour หรือ ot มาแสดง
    results.data.forEach((doc) => {
      lists.push({
        ...doc,
        timeAttendanceId: doc.id,
        manHourString: doc.processManHour ? doc.manHourString : "00:00",
        otString: doc.processOt ? doc.otString : "00:00",
      });
    });
    setList(lists);

    //โหลดข้อมูลรายการลา
    const promiseImportLeave = lists.map(async (d) => {
      return await downloadImportLeave(d);
    });

    const prepareList = await Promise.all(promiseImportLeave);
    let prepare = [];
    let errors = [];
    prepareList.forEach((doc) => {
      if (doc.status) {
        prepare.push(doc.data);
      } else {
        errors.push(doc);
      }
    });

    if (errors.length > 0) {
      dialog.alert({
        message: `Error : ${errors.map((d) => d.message).join(", ")}`,
      });
      return;
    }
    setList(prepare);

    dialog.cancle();
  };

  const downloadImportLeave = async (values) => {
    return new Promise(async (resolve) => {
      if (!values.timeAttendanceId) {
        resolve({
          status: false,
          message: "timeAttendanceId not found",
          data: [],
        });
      }
      const queryLeaves = [["timeAttendanceId", "==", values.timeAttendanceId]];
      const getAllLeaveId = await getFirestoreDataWithMultiQuery(
        `TotalLeave`,
        queryLeaves
      );
      if (!getAllLeaveId.status) {
        resolve({
          status: false,
          message: getAllLeaveId.message,
          data: [],
        });
      }
      resolve({
        status: true,
        message: "success",
        data: { ...values, importLeave: getAllLeaveId.data },
      });
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleDeleteSelectAll = async (values) => {
    const isConfirm = await dialog.confirm({
      title: `ยืนยันการลบข้อมูล`,
      message: `คุณต้องการลบข้อมูลที่เลือกทั้งหมดหรือไม่?`,
    });
    if (!isConfirm) {
      return;
    }
    dialog.loading();
    let prepare = values.map(async (d) => {
      return await prepareDelete(d);
    });

    const prepareResults = await Promise.all(prepare);
    let paths = [];
    prepareResults.forEach((doc) => {
      if (doc.status) {
        paths = [...paths, ...doc.data];
      }
    });
    const syncResults = await updateFirestoreDataTransaction(paths, {
      status: "N",
    });
    if (!syncResults.status) {
      await dialog.alert({ message: syncResults.message });
      return;
    }
    await dialog.alert({ message: "ลบข้อมูลสำเร็จ" });
    downloadData();
  };

  const prepareDelete = async (data) => {
    if (!data?.id) {
      return { status: false, message: "id not found", data: data };
    }
    const timeAttendanceId = data?.id;

    const queryLeaves = [["timeAttendanceId", "==", timeAttendanceId]];
    const getAllLeaveId = await getFirestoreDataWithMultiQuery(
      `TotalLeave`,
      queryLeaves
    );
    if (!getAllLeaveId.status) {
      return { status: false, message: getAllLeaveId.message, data: data };
    }

    let docPaths = [`TimeAttendance/${data.id}`];
    if (getAllLeaveId.data.length > 0) {
      getAllLeaveId.data.forEach((doc) => {
        docPaths.push(`TotalLeave/${doc.id}`);
      });
    }

    return { status: true, message: "success", data: docPaths };
  };

  const handleOptions = (values) => {
    const { option } = values;

    if (option === "viewLeave") {
      setShowPopupImportLeaveView(true);
      setPopupLeaveViewId(values.id);
    }
    if (option === "viewEditHistory") {
      setShowPopupEdit(true);
      setPopupEditId(values.id);
    }

    if (option === "viewAlert") {
      const valid = validatesWarningAttendance(values) || {};
      if (!valid.status) {
        dialog.alert({
          title: `แจ้งเตือน`,
          message: valid.message,
        });
      }

      // setShowPopupAlert(true);
      // setPopupAlertId(values.id);
    }
  };

  const syncUpdateLeave = async (values) => {
    // Ensure values object is valid
    const { isChange } = values || {};
    if (!isChange) {
      return;
    }

    console.log("ON SYNC", values);
    dialog.loading();

    let importLeaveLog = [...(values?.updateValues?.importLeaveLog || [])];
    let importLeaveLogData = [];

    try {
      // Add leave using addDoc instead of setDoc inside a transaction
      const importLeaveAdd = values?.importLeaveAdd || [];
      if (importLeaveAdd.length > 0) {
        let prepareImportLeave = [];
        for (let index = 0; index < importLeaveAdd.length; index++) {
          const d = importLeaveAdd[index];
          const convertData = {
            ...convertImportLeavePattern(d),
            attactment: d.file ? URL.createObjectURL(d.file) : "",
            ...values.employeeRef,
            timeAttendanceId: values.timeAttendanceId,
            transactionId: values?.userTransaction?.transactionId || null,
          };
          delete convertData.file;

          console.log(`Processing leave data at index ${index}:`, convertData);

          // Use addDoc to automatically generate an ID for the new document
          const docRef = await addDoc(
            collection(firestore, "TotalLeave"),
            convertData
          );
          const newDocId = docRef.id;

          // Add to log data
          importLeaveLogData.push({
            ...convertData,
            id: newDocId, // Store the new generated ID
            transactionType: "initial",
          });

          prepareImportLeave.push(convertData);
        }

        console.log("Prepared leave data to add:", prepareImportLeave);
      }

      await runTransaction(firestore, async (transaction) => {
        // Update leave if needed inside a transaction
        const importLeaveUpdate = values?.importLeaveUpdate || [];
        if (importLeaveUpdate.length > 0) {
          importLeaveUpdate.forEach((d, index) => {
            console.log(`Updating leave data at index ${index}:`, d);
            const docRef = doc(firestore, `TotalLeave/${d.id}`);
            if (!d.id) {
              throw new Error(
                `Document ID is missing for leave update at index ${index}`
              );
            }

            transaction.update(docRef, { status: "N" });
            importLeaveLogData.push({
              ...d,
              transactionType: "remove",
              status: "N",
            });
          });
        }

        // Update TimeAttendance document
        const timeAttendanceRef = doc(
          firestore,
          `TimeAttendance/${values.timeAttendanceId}`
        );

        // Prepare importLeaveLog
        importLeaveLog.push({
          data: importLeaveLogData,
          ...(values.userTransaction || {}),
          transactionType: "initial",
        });

        transaction.update(timeAttendanceRef, {
          ...values.updateValues,
          importLeaveLog: importLeaveLog,
        });
      });

      // Fetch the latest leave data after transaction completes
      const importLeaveResult = await getFirestoreDataWithMultiQuery(
        "TotalLeave",
        [["timeAttendanceId", "==", values.timeAttendanceId]]
      );

      if (!importLeaveResult.status) {
        dialog.alert({
          message: `Error fetching leave data: ${importLeaveResult.message}`,
        });
        return;
      }
      const updateImportLeave = await updateFirestoreData(
        `TimeAttendance/${values.timeAttendanceId}`,
        { importLeave: importLeaveResult?.data || [] }
      );
      if (!updateImportLeave.status) {
        await dialog.alert({
          message: `การอัพเดทข้อมูล importLeave กรุณาแจ้งโปรแกรมเมอร์ทันที !!! เพื่อไม่ให้เกิดข้อผิดพลาด : ${updateImportLeave.message}`,
        });
      }

      // Success
      dialog.alert({ message: "บันทึกข้อมูลสำเร็จ" });
      setPopupLeaveViewId(null);
      setShowPopupImportLeaveView(false);
      downloadData();
    } catch (error) {
      // Handle transaction failure
      console.error("Transaction failed: ", error);
      await dialog.alert({
        message: `Transaction failed: ${error.message}`,
      });
    }
  };

  const handleSaveFingerPrint = async (values) => {};

  return (
    <View
      style={{ width: "100%", minHeight: "100vh", backgroundColor: "#EBEBEB" }}
    >
      {showPopupImportLeaveView ? (
        <PopupImportLeaveView
          refId={popupLeaveViewId}
          onEnter={(values) => {
            syncUpdateLeave(values);
          }}
          onClose={() => {
            setPopupLeaveViewId(null);
            setShowPopupImportLeaveView(false);
          }}
        />
      ) : null}

      {showPopupEdit ? (
        <PopupEditFingerPrint
          refId={popupEditId}
          onEnter={(values) => {
            handleSaveFingerPrint(values);
          }}
          onClose={() => {
            setPopupEditId(null);
            setShowPopupEdit(false);
          }}
        />
      ) : null}
      <Container style={Styles.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <View style={Styles.headerContainer}>
              <TouchableOpacity
                onPress={() => {
                  handleBack();
                }}
              >
                <FaChevronLeft style={{ fontSize: 25, color: "#4652D1" }} />
              </TouchableOpacity>
              <View style={{ width: 10 }} />
              <View>
                <Text style={Styles.textTitle}>จัดการข้อมูลเวลา</Text>
                <Text style={{ color: "#666666" }}>
                  ดู/แก้ไข ข้อมูลเวลา และการสืบค้นข้อมูล
                </Text>
              </View>
            </View>
          </Grid>
        </Grid>
        <View style={Styles.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Text>เลือกวันที่เริ่มต้น - วันที่สิ้นสุด</Text>
              <DatePickerRange
                startDate={searchData.startDate}
                endDate={searchData.endDate}
                placeholder={"เลือกวันที่เริ่มต้น - วันที่สิ้นสุด"}
                isTop={false}
                styles={{ width: "100%", height: 40 }}
                onChange={(values) => {
                  const { startDate, endDate } = values;
                  if (startDate && endDate) {
                    setSearchData({
                      ...searchData,
                      startDate,
                      endDate,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Text>เลือก บริษัท</Text>
              <Dropdown
                value={searchData.enterpriseId}
                valueType={"string"}
                placeholder="เลือกบริษัท"
                list={enterpriseList}
                style={{ height: 40 }}
                onChange={(values) => {
                  const { value } = values;
                  setSearchData({
                    ...searchData,
                    enterpriseId: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Text>เลือก สาขา</Text>
              <Dropdown
                value={searchData.branchId}
                valueType={"string"}
                placeholder="เลือกสาขา"
                list={branchList}
                style={{ height: 40 }}
                onChange={(values) => {
                  const { value } = values;
                  setSearchData({
                    ...searchData,
                    branchId: value,
                  });
                }}
              />
            </Grid>
          </Grid>

          <View
            style={{
              flex: 1,
              marginTop: 20,
            }}
          >
            <TableAttendanceView
              pageControl={pageControl}
              tableData={list}
              onChange={(row) => {
                const findIndex = list.findIndex((d) => {
                  return d.id === row.id;
                });
                const newList = [...list];
                newList[findIndex] = row;
                setList(newList);
              }}
              onCheck={(row) => {
                const findIndex = list.findIndex((d) => {
                  return d.id === row.id;
                });
                const newList = [...list];
                newList[findIndex] = row;
                setList(newList);
              }}
              onCheckAll={(rows) => {
                setList(rows);
              }}
              onDeleteSelectAll={(values) => {
                handleDeleteSelectAll(values);
              }}
              onSelectPage={(data) => {
                setPageControl({
                  ...pageControl,
                  ...data,
                });
              }}
              onOption={(values) => {
                handleOptions(values);
              }}
            />
          </View>
        </View>
      </Container>
    </View>
  );
};

const Styles = {
  container: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 20,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
  },
  textTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  contentContainer: {
    width: "100%",
    height: 1000,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
    marginTop: 20,
    maxHeight: 1000,
  },
};
export default ManageTimeAttendance;
