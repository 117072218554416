import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity, ScrollView, Modal } from "react-native";
//function
//Icons
//Component
import Calendar from "../Calendar";

const CalendarPopup = (props) => {
  const { show, date, onSelect, onCancle } = props;

  return (
    <React.Fragment>
      {show && (
        <Modal visible={show} transparent={true}>
          <View
            style={{
              position: "fixed",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              zIndex: 9999,
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {}}
          >
            <View
              style={{
                width: "90%",
                maxWidth: 350,
                padding: 20,
                backgroundColor: "#FFF",
                borderRadius: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Calendar
                birthDate={date}
                onSelect={(date) => {
                  onSelect && onSelect(date);
                }}
                onCancle={() => {
                  onCancle && onCancle();
                }}
              />
            </View>
          </View>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default CalendarPopup;
