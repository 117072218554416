import React, { useState, useContext, useCallback } from "react";
//Component
import PopupMessage from "./PopupMessage";
import PopupConfirm from "./PopupConfirm";
import Loading from "./Loading";

const PopupContext = React.createContext();
export function useDialog() {
  return useContext(PopupContext);
}

export function PopupContextProvider({ children }) {
  const [portal, setPortal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const alert = useCallback(async (values) => {
    return new Promise((resolve) => {
      const resolveClosePopup = () => {
        if (!values.forceOpen) {
          resolve(true);
          setPortal(null);
        }
      };
      setPortal(
        <PopupMessage
          {...values}
          onConfirm={() => resolveClosePopup()}
          onReject={() => resolveClosePopup()}
        />
      );
    });
  }, []);

  const confirm = useCallback(async (values) => {
    return new Promise((resolve) => {
      const resolveClosePopup = (status) => {
        resolve(status);
        setPortal(null);
      };
      setPortal(
        <PopupConfirm
          {...values}
          onConfirm={() => resolveClosePopup(true)}
          onCancle={() => resolveClosePopup(false)}
        />
      );
    });
  }, []);

  const loading = useCallback((values) => {
    return new Promise((resolve) => {
      if (!isLoading) {
        setIsLoading(true);
        setPortal(<Loading />);
      }
    });
  }, []);

  const cancle = useCallback((values) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setPortal(null);
        setIsLoading(false);
      }, 200);
    });
  }, []);

  return (
    <PopupContext.Provider
      value={{
        alert,
        confirm,
        loading,
        cancle,
      }}
    >
      {children}
      {portal}
    </PopupContext.Provider>
  );
}
