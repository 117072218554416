import React, { useEffect, useState } from "react";
import { View } from "react-native-web";
import Select from "react-select";
//icons
import { RiArrowDownSLine } from "react-icons/ri";

const Dropdown = (props) => {
  const {
    list = [],
    placeholder,
    disable = false,
    style,
    value,
    valueType,
    findWithId,
  } = props;
  const { onChange, onInputChange } = props;

  const [selectValue, setSelectValue] = useState();

  useEffect(() => {
    if (value !== null && value !== undefined && list.length > 0 && valueType) {
      if (findWithId) {
        let check =
          valueType === "string"
            ? list.filter((d) => {
                return String(d.id) === String(value);
              })
            : list.filter((d) => {
                return Number(d.id) === Number(value);
              });
        if (check.length > 0) {
          setSelectValue(check[0]);
        }
      } else {
        let check =
          valueType === "string"
            ? list.filter((d) => {
                return String(d.value) === String(value);
              })
            : list.filter((d) => {
                return Number(d.value) === Number(value);
              });
        if (check.length > 0) {
          setSelectValue(check[0]);
        }
      }
    } else if (!value && list && valueType) {
      setSelectValue(null);
    }
  }, [value, list]);

  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const handleInputChange = (value) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newTimeout = setTimeout(() => {
      onInputChange && onInputChange(value);
    }, 1000); // 1000 ms delay

    setDebounceTimeout(newTimeout);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      width: "100%",
      height: style?.height || 50,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 10000, // Ensure the dropdown is always on top
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected
        ? "#FFFFFF"
        : state.isFocused
        ? "#FFFFFF"
        : "#000000", // Change color of unselected options here
    }),
  };

  return (
    <View
      style={{
        width: "100%",
        zIndex: 1,
        ...style,
      }}
    >
      <Select
        value={selectValue}
        options={list ? list : []}
        placeholder={placeholder ? placeholder : ""}
        isDisabled={disable}
        components={{
          DropdownIndicator: () => (
            <RiArrowDownSLine
              style={{
                ...Styles.dropdownIcon,
                color: disable ? "#8A91A1" : "#0F1011",
              }}
            />
          ),
          IndicatorSeparator: () => null,
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#5662E1",
            primary: "#4652D1",
          },
        })}
        onInputChange={handleInputChange}
        onChange={(values) => {
          console.log("on select", values);
          onChange && onChange(values);
        }}
        autoFocus={false}
        menuPortalTarget={document.body} // Ensure the dropdown menu is rendered in the portal
        menuPosition="fixed" // Use fixed positioning
        menuPlacement="auto" // Automatically decide placement (top/bottom)
        styles={customStyles}
      />
    </View>
  );
};

const Styles = {
  dropdownIcon: {
    position: "absolute",
    fontSize: 20,
    color: "#0F1011",
    right: 8,
  },
};

export default Dropdown;
