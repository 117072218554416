import { Modal, View } from "react-native-web";
import { Animated } from "react-animated-css";
import { WaveLoading } from "react-loadingg";

const Loading = (props) => {
  return (
    <Modal transparent={true} visible={true}>
      <View style={Styles.modalContainer}>
        <Animated
          style={Styles.boxBackground}
          animationIn="fadeIn"
          animationInDuration={200}
          isVisible={true}
        >
          <View style={{ ...Styles.boxPopup }}>
            <Animated
              animationIn="bounceIn"
              animationInDuration={400}
              isVisible={true}
            >
              <WaveLoading color="#5662E1" />
            </Animated>
          </View>
        </Animated>
      </View>
    </Modal>
  );
};

const Styles = {
  modalContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 9999,
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    padding: 40,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
    marginLeft: "clamp(20px, 2vw, 40px)",
    marginRight: "clamp(20px, 2vw, 40px)",
  },
  boxBackground: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    justifyContent: "center",
    alignItems: "center",
  },
  boxPopup: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#0F1011",
  },
  textDesc: {
    fontSize: 15,
    color: "#696D79",
  },
  button: {
    width: "100%",
    marginTop: 20,
  },
  containerButton: {
    flexDirection: "row",
  },
};

export default Loading;
