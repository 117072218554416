import React from "react";
import { Text } from "react-native-web";
import { Button as ButtonComponent } from "shards-react";

const Button = (props) => {
  const {
    buttonText,
    color,
    style,
    isBorder,
    styleText,
    icon,
    disable = false,
  } = props;
  const { onClick } = props;
  return (
    <ButtonComponent
      style={{
        width: "100%",
        height: 40,
        display: "flex",
        flexDirection: "row",
        backgroundColor: disable
          ? colorOfButton("disable")
          : isBorder
          ? "rgba(0,0,0,0)"
          : colorOfButton(color),
        borderWidth: isBorder ? 1 : 0,
        borderColor: isBorder ? colorOfButton(color) : "",
        outline: 0,
        shadowColor: "",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
      theme="light"
      onClick={() => {
        if (!disable) {
          onClick && onClick();
        }
      }}
    >
      {icon ? icon : null}
      <Text
        style={{
          color: disable
            ? colorOfText("disable")
            : isBorder
            ? colorOfButton(color)
            : colorOfText(color),
          fontWeight: "bold",
          ...styleText,
          alignSelf: "center",
        }}
      >
        {buttonText ? buttonText : ""}
      </Text>
    </ButtonComponent>
  );
};

const colorOfButton = (color) => {
  switch (color) {
    case "primary":
      return "#4652D1";
    case "secondary":
      return "#D14652";
    case "light":
      return "#EDEEF0";
    case "disable":
      return "#EDEEF0";
    default:
      return color;
  }
};

const colorOfText = (color) => {
  switch (color) {
    case "primary":
      return "#FFFFFF";
    case "secondary":
      return "#FFFFFF";
    case "light":
      return "#8A91A1";
    case "disable":
      return "#8A91A1";
    default:
      return;
  }
};

export default Button;
