import React, { useState } from "react";
import moment from "moment";
import { TouchableOpacity, Text } from "react-native-web";

const DayBoxRange = (props) => {
  const [hover, setHover] = useState(false);
  const { currentDate, day, isBetween, isValid } = props;
  const { isStartDate, isEndDate } = props;

  const { onPress } = props;

  const is1 = moment(currentDate).set("date", day);
  const is2 = moment();

  return (
    <TouchableOpacity
      style={{
        width: 32,
        height: 32,
        backgroundColor: getBackgroundColor(
          hover,
          isValid,
          isStartDate,
          isEndDate,
          isBetween
        ),
        borderTopLeftRadius: 32,
        borderTopRightRadius: 32,
        borderBottomLeftRadius: 32,
        borderBottomRightRadius: 32,
        alignItems: "center",
        justifyContent: "space-around",
        margin: 4,
        borderWidth: is1.isSame(is2, "date") ? 1 : 0,
        borderColor: "#D5D7DD",
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onPress={() => {
        if (isValid) {
          onPress && onPress(day);
        }
      }}
    >
      <Text
        style={{
          fontSize: 14,
          color:
            (isStartDate || isEndDate) && isValid
              ? "#FFFFFF"
              : isValid
              ? "#0F1011"
              : "#B9BDC7",
        }}
      >
        {day || ""}
      </Text>
    </TouchableOpacity>
  );
};

const getBackgroundColor = (
  hover,
  isValid,
  isStartDate,
  isEndDate,
  isBetween
) => {
  if (!isValid) {
    return "";
  }
  if (hover) {
    if (isStartDate || isEndDate) {
      return "#4652D1";
    } else {
      return "#CBD2FF";
    }
  } else {
    if (isStartDate || isEndDate) {
      return "#4652D1";
    } else if (isBetween) {
      return "#CBD2FF";
    } else {
      return "";
    }
  }
};

export default DayBoxRange;
