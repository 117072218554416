export function formatNumber(value) {
  return Number(String(value).replace(/[^0-9]+/g, ""));
}

export function formatNumberString(value) {
  return String(value).replace(/[^0-9]+/g, "");
}

export function formatDecimal(value) {
  var num = parseFloat(value);
  return isNaN(num) ? "" : num.toFixed(2);
}
