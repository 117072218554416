import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  auth,
  getRealtimeData,
  getRealtimeDataWithQuery,
  signOut,
} from "../../service/firebase";
import { useNavigate } from "react-router-dom";
import { View, Text } from "react-native-web";
import moment from "moment";
import { useDialog } from "../Modal/Popup";

const AuthPage = (props) => {
  const {
    roleList,
    needLogin = false,
    isHeader = false,
    requireRoleListIfNotAdmin = [],
  } = props;
  const { children } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dialog = useDialog();

  const { user } = useSelector((state) => {
    return state;
  });
  const [valid, setValid] = React.useState(false);
  const [check, setCheck] = React.useState(false);

  React.useEffect(() => {
    checkPermission(roleList);
  }, [roleList, user]);

  const checkPermission = async (value) => {
    if (needLogin) {
      if (!user) {
        await logout();
        return;
      }
    }
    if (!user?.uid) {
      await logout();
      return;
    }

    //check permission
    const result = await getRealtimeData(`User/${user.uid}`);
    if (!result.status) {
      setCheck(true);
      return;
    }
    const { role, useRole } = result.data;
    if (!role) {
      setCheck(true);
      return;
    }
    const valid = value.some((d) => {
      return String(d) === String(role);
    });

    if (!valid) {
      setCheck(true);
      setValid(false);
      return;
    }
    if (requireRoleListIfNotAdmin.length > 0 && role !== "admin") {
      const valid = requireRoleListIfNotAdmin.some((d) => {
        return useRole.some((item) => {
          return String(item) === String(d);
        });
      });
      if (!valid) {
        setCheck(true);
        setValid(false);
        return;
      }
    }
    setCheck(true);
    setValid(true);
  };

  const logout = async () => {
    dispatch({ type: "Logout" });
    await signOut(auth);
    navigate("/login");
  };

  return (
    <React.Fragment>
      <RenderChildren
        children={children}
        check={check}
        valid={valid}
        isHeader={isHeader}
      />
    </React.Fragment>
  );
};

const RenderChildren = ({ children, check, valid, isHeader }) => {
  if (!check && !valid) {
    return null;
  }
  if (check && !valid && !isHeader) {
    return <PermissionDenide />;
  }
  if (check && !valid && isHeader) {
    return null;
  }
  if (check && valid) {
    return children;
  }
};

const PermissionDenide = () => {
  return (
    <View
      style={{
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text style={{ fontSize: 20, fontWeight: "bold" }}>
        Permission Denied
      </Text>
    </View>
  );
};
export default AuthPage;
