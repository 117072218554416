import React, { useEffect, useState } from "react";
import { View, Text, ScrollView } from "react-native-web";
import PopupBackdrop from "../../../../component/Modal/PopupBackDrop";
import { Divider } from "rsuite";
import { useDialog } from "../../../../component/Modal/Popup";
import Button from "../../../../component/Button/Button";
import { calculateLifeTimeOfDate } from "../../../../function/supportMoment";
import Dropdown from "../../../../component/Dropdown/Dropdown";
import DatePicker from "../../../../component/CalendarV2/DatePicker";
import moment from "moment";
import TimePicker from "../../../../component/time/TimePicker";
import TextField from "../../../../component/Textfield/TextField";
import BoxUploadFile from "../../../../component/uploadbox/BoxUploadFile";
import { getLogoEnterprice } from "../../../../component/ReportHrm/helper";
import {
  currentTimestampFirestore,
  getFirestoreData,
  getRealtimeData,
} from "../../../../service/firebase";
import { getEnterprise } from "../../../../function/employee/employeeAction";
import { formatTime, processLeaveTotal } from "../functions/calculateLeave";
import { useSelector } from "react-redux";
import { get } from "firebase/database";

const PopupImportLeave = (props) => {
  const dialog = useDialog();
  const { data, attendanceLeaveList = [], isSave = false, dateFix } = props;
  const { onClose, onEnter } = props;
  const { user } = useSelector((state) => {
    return state;
  });

  //state
  const [enterpriseList, setEnterpriseList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [bossApproveList, setBossApproveList] = useState([]);
  const [hrApproveList, setHrApproveList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeInEnterpriseList, setEmployeeInEnterpriseList] = useState([]);

  const [leaveTypeList, setLeaveTypeList] = useState([
    { label: "ระหว่างวัน", value: "half" },
    { label: "เต็มวัน", value: "full" },
  ]);
  const [values, setValues] = useState({
    employeeId: "",
    employeeNumber: "",
    enterpriseId: "",
    branchId: "",
    leaveId: "",
    leaveDayType: "full",
    date: moment(),
    startDate: moment(),
    endDate: moment(),
    startTime: null,
    endTime: null,
    reasonLeave: "",
    attactment: "",
    bossId: "",
    hrId: "",
    transactionDate: currentTimestampFirestore(),
    createDate: currentTimestampFirestore(),
    status: "Y",
    leaveType: "leave",
  });

  useEffect(() => {
    downloadEnterprise();
  }, []);

  useEffect(() => {
    if (data && attendanceLeaveList.length > 0) {
      downloadLeaveList();
    }
  }, [data, attendanceLeaveList]);

  useEffect(() => {
    if (enterpriseList.length > 0 && data) {
      console.log("DATA", data);
      downloadEmployeeList();
    }
  }, [enterpriseList, data]);

  useEffect(() => {
    if (employeeList.length > 0) {
      downloadHrApproveList();
    }
  }, [employeeList]);

  useEffect(() => {
    if (dateFix) {
      setValues({
        ...values,
        date: dateFix,
        startDate: dateFix,
        endDate: dateFix,
      });
    }
  }, [dateFix]);

  const downloadEnterprise = async () => {
    const result = await getEnterprise();
    if (!result.status) {
      await dialog.alert({ message: result.message });
      return;
    }
    setEnterpriseList(result.data);
  };

  const downloadLeaveList = async () => {
    const processList = await processLeaveTotal(
      attendanceLeaveList,
      data.id, // id คือ employeeId
      "leave",
      data?.importLeave || [] //prepare รายการลาที่ยังไม่ได้บันทึกลง firestore
    );
    if (!processList.status) {
      await dialog.alert({ message: processList.message });
      return;
    }
    let init = [];
    processList.data.forEach((process) => {
      init.push({
        value: process.id,
        label: process.displaySelectLabel,
        processManHour: process.processManHour,
        titleLabel: process.titleLabel,
        ...process,
      });
    });
    setLeaveList(init);
  };

  const downloadEmployeeList = async () => {
    const result = await getRealtimeData("Employee");
    if (!result.status) {
      await dialog.alert({ message: result.message });
      return;
    }
    let list = [];
    let listAll = [];
    console.log("downloadEmployeeList", result.data, enterpriseList);
    Object.keys(result.data).forEach((key) => {
      const employee = result.data[key];
      if (employee?.active === "D" || !employee?.status) {
        return;
      }

      const findEnterprise = enterpriseList.find((d) => {
        return d.value === result?.data[key]?.enterpriseId;
      });
      if (employee?.status && employee?.enterpriseId === data?.enterpriseId) {
        list.push({
          value: key,
          label: `${findEnterprise ? findEnterprise.label : ""} ${
            result?.data[key]?.firstname
          } ${result?.data[key]?.lastname}`,
          ...result.data[key],
        });
      }
      listAll.push({
        value: key,
        label: `${findEnterprise ? findEnterprise.label : ""} ${
          result?.data[key]?.firstname
        } ${result?.data[key]?.lastname}`,
        ...result.data[key],
      });
    });
    setEmployeeList(listAll);
    setEmployeeInEnterpriseList(list);

    //เฉพาะผู้มีค่าตำแหน่ง >= 3000
    const filterBoss = list.filter((d) => {
      return (d.salaryPosition || 0) >= 3000;
    });
    //เสริมด้วย custom user
    const customBossResult = await getFirestoreData("BossApprove");
    if (!customBossResult.status) {
      await dialog.alert({
        message: `Error getCustomBoss : ${customBossResult.message}`,
      });
      return;
    }
    //find from database user info
    const findCustomBossList = customBossResult.data.map(async (d) => {
      const findUser = await getRealtimeData(`Employee/${d.employeeId}`);
      if (!findUser.status) {
        return { ...d };
      }
      return {
        id: d.id,
        names: `${findUser.data?.firstname} ${findUser.data?.lastname}`,
        employeeId: d.employeeId,
        value: d.employeeId,
        label: `${findUser.data?.firstname} ${findUser.data?.lastname}`,
      };
    });
    const customBossList = await Promise.all(findCustomBossList);

    setBossApproveList([...filterBoss, ...customBossList]);
  };

  const downloadHrApproveList = async () => {
    const result = await getFirestoreData("HrApprove");
    if (!result.status) {
      await dialog.alert({ message: result.message });
      return;
    }
    const list = [];

    result.data.forEach((d) => {
      const findEmployee = employeeList.find((e) => {
        return String(e.value) === String(d.employeeId);
      });
      const findEnterprise = enterpriseList.find((e) => {
        return e.value === findEmployee?.enterpriseId;
      });
      if (findEmployee) {
        list.push({
          id: d.id,
          names: `${
            findEnterprise ? `บริษัท : ${findEnterprise.label}` : ""
          }, ชื่อ : ${
            findEmployee
              ? `${findEmployee?.firstname} ${findEmployee?.lastname}`
              : ""
          }`,
          employeeId: findEmployee.value,
          label: findEmployee
            ? `${findEmployee?.firstname} ${findEmployee?.lastname}`
            : "",
          value: findEmployee.value,
        });
      }
    });
    setHrApproveList(list);
  };

  const handleCancle = async () => {
    if (onClose) {
      onClose();
    }
  };

  const validates = async () => {
    let errros = "";
    if (!values.leaveId) {
      errros += "กรุณาเลือกรายการลา\n";
    }
    if (!values.leaveDayType) {
      errros += "กรุณาเลือกประเภทการลา\n";
    }
    if (values?.leaveDayType === "full") {
      if (!values.startDate) {
        errros += "กรุณาเลือกวันที่เริ่มต้นการลา\n";
      }
      if (!values.endDate) {
        errros += "กรุณาเลือกวันที่สิ้นสุดการลา\n";
      }
      if (values.startDate && values.endDate) {
        if (moment(values.startDate).isAfter(values.endDate)) {
          errros += "วันที่เริ่มต้นการลาต้องน้อยกว่าวันที่สิ้นสุดการลา\n";
        }
      }
    } else if (values?.leaveDayType === "half") {
      if (!values.date) {
        errros += "กรุณาเลือกวันที่\n";
      }
      if (!values.startTime) {
        errros += "กรุณาเลือกเวลาเริ่มต้นการลา\n";
      }
      if (!values.endTime) {
        errros += "กรุณาเลือกเวลาสิ้นสุดการลา\n";
      }
      if (values.startTime && values.endTime) {
        if (
          moment(values.startTime, "HH:mm").isAfter(
            moment(values.endTime, "HH:mm")
          )
        ) {
          errros += "เวลาเริ่มต้นการลาต้องน้อยกว่าเวลาสิ้นสุดการลา\n";
        }
      }
      if (!isWithinWorkingHours(values.startTime)) {
        errros += "เวลาเริ่มต้นการลาต้องอยู่ในช่วงเวลาทำงาน\n";
      }
      if (!isWithinWorkingHours(values.endTime)) {
        errros += "เวลาสิ้นสุดการลาต้องอยู่ในช่วงเวลาทำงาน\n";
      }
    }

    if (!values.bossId) {
      errros += "กรุณาเลือกหัวหน้างานผู้อนุมัติ\n";
    }
    if (!values.hrId) {
      errros += "กรุณาเลือกฝ่ายบุคคล\n";
    }

    //ตรวจสอบความถูกต้องของสิทธิการลาคงเหลือ
    console.log("leaveList", leaveList);

    if (errros) {
      await dialog.alert({ message: errros });
      return false;
    }
    return true;
  };

  // Function to check if time is within the limits
  function isWithinWorkingHours(time) {
    let startLimit = moment("08:00", "HH:mm");
    let endLimit = moment("17:00", "HH:mm");
    return moment(time, "HH:mm").isBetween(startLimit, endLimit, null, "[]");
  }

  const handleSubmit = async () => {
    const validate = await validates();
    if (!validate) {
      return;
    }
    if (!user) {
      await dialog.alert({
        message: "ไม่พบผู้ใข้งาน ไม่สามารถดำเนินการต่อได้",
      });
      return;
    }

    const userTransacntion = await getRealtimeData(`User/${user.uid}`);
    if (!userTransacntion.status) {
      return;
    }
    let params = { ...values };
    const userTransactionData = userTransacntion.data;
    params.userTransaction = { ...userTransactionData };
    params.status = "Y"; //เปิดใช้งาน
    if (params.isSubTitle) {
      params.leaveId = params.parentId;
      const findLeaveMaster = attendanceLeaveList.find(
        (d) => d.id === params.parentId
      );
      const findSubTitle = findLeaveMaster?.subTitle?.find(
        (d) => d.id === params.subTitleId
      );

      if (findSubTitle) {
        params.titleLabel = findSubTitle.label;
      }
    }

    //คำนวน totalMinute
    if (values.leaveDayType === "full") {
      params.totalMinute = 60 * 8;
    } else {
      const startTime = moment(values.startTime, "HH:mm");
      const endTime = moment(values.endTime, "HH:mm");
      const totalMinute = endTime.diff(startTime, "minutes");
      params.totalMinute = totalMinute;
    }

    //ตรวจสอบว่าใช้สิทธิครบหรือยัง
    const findLeaveUse = leaveList.find((d) => {
      return d.id === values.leaveId;
    });
    if (!findLeaveUse) {
      await dialog.alert({ message: "ไม่พบข้อมูลหัวข้อการลา" });
      return;
    }
    if (!findLeaveUse?.isUnlimited) {
      //กรณีมีสิทธิจำกัด
      const checkCompare =
        Number(findLeaveUse?.totalMinuteLeftNumber || 0) -
        Number(params.totalMinute);
      if (checkCompare < 0) {
        await dialog.alert({
          message: `สิทธิการลาไม่เพียงพอ : ${
            findLeaveUse?.displaySelectLabel
          } , จำนวนที่คุณใช้ ${formatTime(params?.totalMinute || 0)}`,
        });
        return;
      }
    }
    //

    const confirm = await dialog.confirm({
      title: "ยืนยันการบันทึกการลา",
      message: "คุณต้องการบันทึกการลาใช่หรือไม่",
    });
    if (!confirm) {
      return;
    }

    if (isSave) {
      //กรณีไม่ได้บันทึกหน้านำเข้าเวลา ให้บันทึกทันที
    } else {
      if (onEnter) {
        onEnter(params);
      }
    }
  };

  return (
    <PopupBackdrop>
      <View style={Styles.container}>
        <Header
          onClose={() => {
            handleCancle();
          }}
          onEnter={() => {
            handleSubmit();
          }}
        />
        <ScrollView>
          {data?.enterprise && (
            <img
              alt={"logo"}
              style={{ width: 120, margin: 10, alignSelf: "center" }}
              src={getLogoEnterprice(data?.enterprise)}
            />
          )}

          <EmployeeProfile data={data} />
          <Text style={Styles.titleInput}>
            รายการลา <Mark />
          </Text>
          <View style={{ height: 8 }} />
          <Dropdown
            list={leaveList}
            placeholder="เลือกรายการลา"
            value={values.leaveId}
            valueType="string"
            onChange={(value) => {
              const findLeave = leaveList.find((d) => {
                return d.value === value.value;
              });
              setValues({
                ...values,
                leaveId: value.value,
                titleLabel: findLeave ? findLeave?.titleLabel : "ไม่พบข้อมูล",
                processManHour: value.processManHour,
                parentId: value.parentId,
                isSubTitle: value.isSubTitle,
                subTitleId: value.subTitleId,
              });
            }}
          />
          <View style={{ height: 15 }} />
          <Text style={Styles.titleInput}>
            ประเภทการลา <Mark />
          </Text>
          <View style={{ height: 8 }} />
          <Dropdown
            list={leaveTypeList}
            placeholder="เลือกประเภทการลา"
            value={values.leaveDayType}
            valueType="string"
            onChange={(value) => {
              setValues({ ...values, leaveDayType: value.value });
            }}
          />
          <View style={{ height: 15 }} />
          {values.leaveDayType === "full" ? (
            <LeaveTypeFull
              dateFix={dateFix}
              values={values}
              onChange={(value) => {
                setValues({ ...values, ...value });
              }}
            />
          ) : (
            <LeaveTypeHalf
              dateFix={dateFix}
              values={values}
              onChange={(value) => {
                setValues({ ...values, ...value });
              }}
            />
          )}
          <View style={{ height: 15 }} />
          <Text style={Styles.titleInput}>เหตุผลในการลา</Text>
          <View style={{ height: 8 }} />
          <TextField
            placeholder={"เหตุผลในการลา"}
            value={values.reasonLeave}
            onChangeText={(value) => {
              setValues({
                ...values,
                reasonLeave: value,
              });
            }}
          />
          <View style={{ height: 15 }} />
          <Text style={Styles.titleInput}>หลักฐานประกอบการลา</Text>
          <View style={{ height: 8 }} />
          <BoxUploadFile
            style={{ maxWidth: "100%", maxHeight: 300 }}
            accept={"image/*,application/pdf"}
            onSelect={(value) => {
              setValues({
                ...values,
                file: value,
              });
            }}
          />
          <View style={{ height: 15 }} />
          <Text style={Styles.titleInput}>
            หัวหน้างานผู้อนุมัติ <Mark />
          </Text>
          <View style={{ height: 8 }} />
          <Dropdown
            list={bossApproveList}
            placeholder={"เลือกหัวหน้างาน"}
            onChange={(value) => {
              setValues({ ...values, bossId: value.value });
            }}
          />
          <View style={{ height: 15 }} />
          <Text style={Styles.titleInput}>
            ฝ่ายบุคคล <Mark />
          </Text>
          <View style={{ height: 8 }} />
          <Dropdown
            list={hrApproveList}
            placeholder={"เลือกฝ่ายบุคคล"}
            onChange={(value) => {
              setValues({ ...values, hrId: value.value });
            }}
          />
          <View style={{ height: 40 }} />
        </ScrollView>
      </View>
    </PopupBackdrop>
  );
};

const Mark = () => {
  return (
    <Text
      style={{
        fontSize: 18,
        color: "#FF0000",
      }}
    >
      *
    </Text>
  );
};
const Header = (props) => {
  const { onClose, onEnter } = props;
  return (
    <View style={{ width: "100%" }}>
      <View style={{ flexDirection: "row" }}>
        <Text style={Styles.title}>บันทึกการลาตามสิทธิ</Text>
        <Button
          style={{ width: 100 }}
          buttonText={"บันทึก"}
          color={"primary"}
          onClick={() => {
            if (onEnter) {
              onEnter();
            }
          }}
        />
        <View style={{ width: 20 }} />
        <Button
          style={{ width: 100 }}
          buttonText={"ปิด"}
          color={"secondary"}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
      </View>
      <Divider />
    </View>
  );
};

const EmployeeProfile = (props) => {
  const { data } = props;
  return (
    <View style={{ width: "100%", marginBottom: 20 }}>
      <Text style={{ fontSize: 17, fontWeight: "bold" }}>ข้อมูลพนักงาน</Text>
      <View style={Styles.profileContainer}>
        <Text>{`ชื่อ : ${`${data?.firstname} ${data?.lastname}`} รหัสพนักงาน : ${
          data?.employeeNumber
        } อายุงาน : ${`${
          calculateLifeTimeOfDate(data?.startJobDate).years
        } ปี ${
          calculateLifeTimeOfDate(data?.startJobDate).months
        }`} เดือน`}</Text>
      </View>
    </View>
  );
};

const LeaveTypeHalf = (props) => {
  const { values, dateFix } = props;
  const { onChange } = props;
  return (
    <View style={{ zIndex: 10 }}>
      <Text style={Styles.titleInput}>
        วันที่ลา <Mark />
      </Text>
      <View style={{ height: 8 }} />
      <DatePicker
        date={values?.date}
        disable={dateFix ? true : false}
        styles={{ width: "100%", height: 40 }}
        placeholder="เลือกวันที่"
        style={{ height: 50 }}
        onChange={(value) => {
          if (onChange) {
            onChange({ ...values, date: value });
          }
        }}
      />
      <View style={{ height: 15 }} />
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 0.5 }}>
          <Text style={Styles.titleInput}>
            ระหว่างเวลา <Mark />
          </Text>
          <View style={{ height: 8 }} />
          <TimePicker
            time={values?.startTime}
            onChange={(values) => {
              if (onChange) {
                onChange({
                  ...values,
                  startTime: `${String(values.hour).padStart(2, "0")}:${String(
                    values.minute
                  ).padStart(2, "0")}`,
                });
              }
            }}
          />
        </View>
        <View style={{ width: 20 }} />
        <View style={{ flex: 0.5 }}>
          <Text style={Styles.titleInput}>
            ถึงเวลา <Mark />
          </Text>
          <View style={{ height: 8 }} />
          <TimePicker
            time={values?.endTime}
            onChange={(values) => {
              if (onChange) {
                onChange({
                  ...values,
                  endTime: `${String(values.hour).padStart(2, "0")}:${String(
                    values.minute
                  ).padStart(2, "0")}`,
                });
              }
            }}
          />
        </View>
      </View>
    </View>
  );
};

const LeaveTypeFull = (props) => {
  const { values, dateFix } = props;
  const { onChange } = props;
  return (
    <View style={{ zIndex: 10 }}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 0.5 }}>
          <Text style={Styles.titleInput}>
            จากวันที่ <Mark />
          </Text>
          <View style={{ height: 8 }} />
          <DatePicker
            date={values?.date}
            disable={dateFix ? true : false}
            styles={{ width: "100%", height: 40 }}
            placeholder="เลือกวันที่"
            style={{ height: 50 }}
            onChange={(value) => {
              if (onChange) {
                onChange({ ...values, startDate: value });
              }
            }}
          />
        </View>
        <View style={{ width: 20 }} />
        <View style={{ flex: 0.5 }}>
          <Text style={Styles.titleInput}>
            ถึงวันที่ <Mark />
          </Text>
          <View style={{ height: 8 }} />
          <DatePicker
            date={values.date}
            disable={dateFix ? true : false}
            styles={{ width: "100%", height: 40 }}
            placeholder="เลือกวันที่"
            style={{ height: 50 }}
            onChange={(value) => {
              if (onChange) {
                onChange({ ...values, endDate: value });
              }
            }}
          />
        </View>
      </View>
    </View>
  );
};

const Styles = {
  container: {
    width: "90vw",
    height: "90vh",
    maxWidth: 800,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
    zIndex: 10,
  },
  content: {
    flex: 1,
    zIndex: 0,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    flex: 1,
  },
  profileContainer: {
    width: "100%",
    flexDirection: "row",
    padding: 15,
    borderRadius: 5,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    marginTop: 20,
  },
  titleInput: {
    fontSize: 16,
  },
};

export default PopupImportLeave;
