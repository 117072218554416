import React, { useState, useRef } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
//components
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
//images
import emptyProduct from "../../../../Asset/Images/emptyProduct.png";
import {
  MdHistoryToggleOff,
  MdManageHistory,
  MdOutlineWorkHistory,
} from "react-icons/md";
//functions
import moment from "moment-timezone";
import TextField from "../../../../component/Textfield/TextField";
import CalendarPopup from "../../../../component/CalendarPopup";
import {
  formatNumber,
  formatNumberString,
} from "../../../../function/numberFormat";
import CustomCheckbox from "../../../../component/checkbox/CheckBox";
import Button from "../../../../component/Button/Button";
import PageButtonNavigateV2 from "../../../../component/Button/PageButtonNavigateV2";
import { IoMdAlert } from "react-icons/io";
import { validatesWarningAttendance } from "../functions/validates";

const TableAttendanceView = (props) => {
  const { tableData = [], pageControl = {} } = props;
  const { employeeList = [], sectionList } = props;
  const {
    onChange,
    onCheckAll,
    onCheck,
    onDeleteSelectAll,
    onSelectPage,
    onOption,
  } = props;
  const tableContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  //state
  const [showCalendar, setShowCalendar] = useState(false);
  const [rowRef, setRowRef] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  const handleScrollLeft = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft -= 100;
      setScrollPosition(tableContainerRef.current.scrollLeft);
    }
  };

  const handleScrollRight = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft += 100;
      setScrollPosition(tableContainerRef.current.scrollLeft);
    }
  };

  const handleCheckBox = (value, data) => {
    if (!data) {
      let init = [...tableData];
      init.forEach((item) => {
        item.isSelect = value;
      });
      if (onCheckAll) {
        onCheckAll(init);
      }
      setSelectAll(value);
      return;
    }

    if (data) {
      if (onChange) {
        setSelectAll(false);
        onCheck({
          ...data,
          isSelect: value,
        });
      }
    }
  };

  const handleChangeTime = (values) => {
    let value = values.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    // Validate first digit
    if (value.length >= 1) {
      const firstDigit = parseInt(value[0], 10);
      if (firstDigit > 2) {
        value = ""; // Remove first digit if greater than 2
      }
    }

    // Validate and remove excess digits for HH
    if (value.length >= 2) {
      const hh = parseInt(value.slice(0, 2), 10);
      if (hh > 23) {
        value = value.slice(0, 1); // Remove excess digits
      }
    }

    // Automatically add ':' after 2 digits
    if (value.length >= 3) {
      value = value.slice(0, 2) + ":" + value.slice(2, 4);
    }

    // Validate first minute digit
    const parts = value.split(":");
    if (parts.length === 2 && parts[1].length > 0) {
      const firstMinuteDigit = parseInt(parts[1][0], 10);
      if (firstMinuteDigit > 5) {
        parts[1] = ""; // Remove excess digit
        value = parts[0] + ":";
      }
    }

    // Validate and remove excess digits for mm
    if (parts.length === 2) {
      const mm = parseInt(parts[1], 10);
      if (mm > 59) {
        value = parts[0] + ":" + parts[1].slice(0, 1); // Remove excess digits
      }
    }

    // Limit length to 5 characters
    if (value.length > 5) {
      value = value.slice(0, 5);
    }

    return value;
  };

  const handleDeleteSelectAll = () => {
    const allSelect = tableData?.filter((d) => {
      return d.isSelect;
    });
    if (onDeleteSelectAll) {
      onDeleteSelectAll(allSelect);
    }
  };

  const filterDataByPage = (data, currentPage, limitItemsPerPage) => {
    const startIndex = (currentPage - 1) * limitItemsPerPage;
    const endIndex = startIndex + limitItemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const RenderOptionHeader = () => {
    if (!tableData) {
      return null;
    }
    const checkAllSelect = tableData?.filter((d) => {
      return d.isSelect;
    });
    if (checkAllSelect.length > 0) {
      return (
        <Grid container spacing={2} style={{ alignItems: "center" }}>
          <Grid item xs={12} md={6}>
            <Text>{`รายการที่เลือกทั้งหมด ${checkAllSelect.length} รายการ`}</Text>
          </Grid>
          <Grid item xs={12} md={3}>
            {/* <Button
              buttonText={"บันทึกรายการที่เลือก"}
              color={"primary"}
              style={{ height: 40 }}
              onClick={() => {
                handleDeleteSelectAll();
              }}
            /> */}
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              buttonText={"ลบรายการที่เลือก"}
              color={"secondary"}
              style={{ height: 40 }}
              onClick={() => {
                handleDeleteSelectAll();
              }}
            />
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const RenderLeaveButton = (props) => {
    const { row } = props;

    const isLeave = () => {
      return (row?.importLeave?.length || 0) === 0;
    };
    return (
      <React.Fragment>
        <View style={{ width: 10 }} />
        <OptionButtons
          title={`จัดการเวลา`}
          subTitle={
            !isLeave() ? `${row?.importLeave?.length || 0} รายการลา` : ""
          }
          subTitleStyle={{ color: "#FF0000" }}
          onPress={() => {
            if (onOption) {
              onOption({ option: "viewLeave", ...row });
            }
          }}
        >
          <MdOutlineWorkHistory
            style={{
              fontSize: 20,
              color: "#4652D1",
            }}
          />
        </OptionButtons>
      </React.Fragment>
    );
  };

  const RenderEditHistoryButton = (props) => {
    const { row } = props;

    const isEdit = () => {
      return row.fingerprint1Log.length > 1;
    };
    return (
      <React.Fragment>
        <View style={{ width: 10 }} />
        <OptionButtons
          title={"ประวัติ\nการแก้ไข"}
          subTitle={isEdit() ? `${row.fingerprint1Log.length} รายการ` : ""}
          subTitleStyle={{ color: "#FF0000" }}
          onPress={() => {
            if (onOption) {
              onOption({ option: "viewEditHistory", ...row });
            }
          }}
        >
          <MdManageHistory style={{ fontSize: 20, color: "#4652D1" }} />
        </OptionButtons>
      </React.Fragment>
    );
  };

  const RenderAlertButton = (props) => {
    const { row } = props;

    return (
      <OptionButtons
        title={"แจ้งเตือน"}
        subTitle={
          validatesWarningAttendance(row).status
            ? ""
            : `${validatesWarningAttendance(row).errors.length} รายการ`
        }
        subTitleStyle={{ color: "#FF0000" }}
        onPress={() => {
          if (onOption) {
            onOption({ option: "viewAlert", ...row });
          }
        }}
      >
        <IoMdAlert style={{ fontSize: 20, color: "#4652D1" }} />
      </OptionButtons>
    );
  };

  return (
    <React.Fragment>
      <CalendarPopup
        show={showCalendar}
        date={moment()}
        onSelect={(date) => {
          onChange &&
            onChange &&
            onChange({
              ...rowRef,
              date: date,
            });
          setRowRef(null);
          setShowCalendar(false);
        }}
        onCancle={() => {
          setRowRef(null);
          setShowCalendar(false);
        }}
      />
      {tableData.length > 0 ? (
        <View
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginBottom: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                handleScrollLeft();
              }}
            >
              <FaArrowAltCircleLeft style={{ fontSize: 30 }} />
            </TouchableOpacity>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <RenderOptionHeader />
            </View>
            <TouchableOpacity
              onPress={() => {
                handleScrollRight();
              }}
            >
              <FaArrowAltCircleRight style={{ fontSize: 30 }} />
            </TouchableOpacity>
          </View>
          <TableContainer ref={tableContainerRef} sx={styles.tableContainer}>
            <Table sx={styles.table}>
              <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
                <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
                  <TableCell sx={{ ...styles.headerCell }}>
                    <CustomCheckbox
                      value={selectAll}
                      borderColor={"#000000"}
                      onCheck={(value) => {
                        handleCheckBox(value);
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    สรุปรายการ
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>วันที่</TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    รหัสพนักงาน
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell, width: 250 }}>
                    ชื่อพนักงาน
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles.headerCell2,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Man-hour
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles.headerCell2,
                      fontWeight: "bold",
                      fontSize: 18,
                      width: 100,
                    }}
                  >
                    Overtime
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell2 }}>
                    การวางนิ้ว 1
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell2 }}>
                    การวางนิ้ว 2
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell2 }}>
                    การวางนิ้ว 3
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell2 }}>
                    การวางนิ้ว 4
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell2 }}>
                    การวางนิ้ว 5
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell2 }}>
                    การวางนิ้ว 6
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterDataByPage(
                  tableData,
                  pageControl.currentPage,
                  pageControl.limitItemsPerPage
                ).map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        ...styles.tableRow,
                        backgroundColor:
                          index % 2 === 0
                            ? "#D8DDF8"
                            : "rgba(255, 255, 255, 1)",
                      }}
                    >
                      <TableCell sx={styles.tableCell}>
                        <CustomCheckbox
                          value={row?.isSelect || false}
                          borderColor={"#000000"}
                          onCheck={(value) => {
                            handleCheckBox(value, row);
                          }}
                        />
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View style={{ flexDirection: "row", width: 150 }}>
                          <RenderAlertButton row={row} />
                          <RenderLeaveButton row={row} />
                          <RenderEditHistoryButton row={row} />
                        </View>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View style={{ width: 120 }}>
                          <TextField
                            value={
                              row.date
                                ? moment(row.date.toDate()).format("DD-MM-YYYY")
                                : ""
                            }
                            placeholder={"วันที่"}
                            onChangeText={(value) => {}}
                          />
                        </View>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <TextField
                          value={row.employeeNumber ? row.employeeNumber : ""}
                          placeholder={"ระบุรหัสพนักงาน"}
                          maxLength={10}
                          onChangeText={(value) => {
                            if (onChange) {
                              onChange({
                                ...row,
                                employeeNumber: formatNumberString(value),
                              });
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ ...styles.tableCell }}>
                        <View style={{ width: 140 }}>
                          <TextField
                            placeholder={"ระบุชื่อพนักงาน"}
                            value={row.employeeName ? row.employeeName : ""}
                            maxLength={50}
                            onChangeText={(value) => {
                              if (onChange) {
                                onChange({
                                  ...row,
                                  employeeName: value,
                                });
                              }
                            }}
                          />
                        </View>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View stytles={styles.fingerprintView}>
                          <TextField
                            placeholder={"Man-hour"}
                            value={row.manHourString ? row.manHourString : ""}
                            disable={true}
                          />
                        </View>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View stytles={{ ...styles.fingerprintView }}>
                          <TextField
                            placeholder={"Overtime"}
                            value={row?.otString || "-"}
                            disable={true}
                          />
                        </View>
                      </TableCell>

                      <TableCell sx={styles.tableCell}>
                        <View stytles={styles.fingerprintView}>
                          <TextField
                            value={
                              row.fingerprint1
                                ? handleChangeTime(row.fingerprint1)
                                : ""
                            }
                            placeholder={"00:00"}
                            disable={false}
                            onChangeText={(values) => {
                              // const value = handleChangeTime(values);
                              // if (onChange) {
                              //   onChange({
                              //     ...row,
                              //     fingerprint1: value,
                              //   });
                              // }
                            }}
                          />
                        </View>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View stytles={styles.fingerprintView}>
                          <TextField
                            placeholder={"00:00"}
                            maxLength={5}
                            value={
                              row.fingerprint2
                                ? handleChangeTime(row.fingerprint2)
                                : ""
                            }
                            disable={false}
                            onChangeText={(values) => {
                              // const value = handleChangeTime(values);
                              // if (onChange) {
                              //   onChange({
                              //     ...row,
                              //     fingerprint2: value,
                              //   });
                              // }
                            }}
                          />
                        </View>
                      </TableCell>

                      <TableCell sx={styles.tableCell}>
                        <View stytles={styles.fingerprintView}>
                          <TextField
                            placeholder={"00:00"}
                            value={
                              row.fingerprint3
                                ? handleChangeTime(row.fingerprint3)
                                : ""
                            }
                            disable={false}
                            onChangeText={(values) => {
                              // const value = handleChangeTime(values);
                              // if (onChange) {
                              //   onChange({
                              //     ...row,
                              //     fingerprint3: value,
                              //   });
                              // }
                            }}
                          />
                        </View>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View stytles={styles.fingerprintView}>
                          <TextField
                            placeholder={"00:00"}
                            value={
                              row.fingerprint4
                                ? handleChangeTime(row.fingerprint4)
                                : ""
                            }
                            disable={false}
                            onChangeText={(values) => {
                              // const value = handleChangeTime(values);
                              // if (onChange) {
                              //   onChange({
                              //     ...row,
                              //     fingerprint4: value,
                              //   });
                              // }
                            }}
                          />
                        </View>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <TextField
                          placeholder={"00:00"}
                          value={
                            row.fingerprint5
                              ? handleChangeTime(row.fingerprint5)
                              : ""
                          }
                          disable={false}
                          onChangeText={(values) => {
                            // const value = handleChangeTime(values);
                            // if (onChange) {
                            //   onChange({
                            //     ...row,
                            //     fingerprint5: value,
                            //   });
                            // }
                          }}
                        />
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View stytles={styles.fingerprintView}>
                          <TextField
                            placeholder={"00:00"}
                            value={
                              row.fingerprint6
                                ? handleChangeTime(row.fingerprint6)
                                : ""
                            }
                            disable={false}
                            onChangeText={(values) => {
                              // const value = handleChangeTime(values);
                              // if (onChange) {
                              //   onChange({
                              //     ...row,
                              //     fingerprint6: value,
                              //   });
                              // }
                            }}
                          />
                        </View>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <PageButtonNavigateV2
            totalCount={tableData.length}
            limitItemsPerPage={pageControl.limitItemsPerPage}
            currentPage={pageControl.currentPage}
            onSelectPage={(page) => {
              onSelectPage &&
                onSelectPage({ ...pageControl, currentPage: page });
            }}
            onChangeLimitPerPage={(value) => {
              onSelectPage({ ...pageControl, limitItemsPerPage: value });
            }}
          />
        </View>
      ) : (
        <EmptyList />
      )}
    </React.Fragment>
  );
};

const OptionButtons = (props) => {
  const { title, subTitle = null, disabled = false } = props;
  const { subTitleStyle } = props;
  const { onPress } = props;
  return (
    <TouchableOpacity
      style={{ justifyContent: "center", alignItems: "center" }}
      activeOpacity={disabled ? 1 : 0.8}
      onPress={() => {
        if (!disabled) {
          onPress && onPress();
        }
      }}
    >
      {props.children}
      <Text
        style={{
          fontSize: 12,
          lineHeight: 12,
          color: disabled ? "#9C9C9C" : "#000000",
          marginTop: 5,
          textAlign: "center",
        }}
      >
        {title || ""}
      </Text>
      {subTitle ? (
        <Text
          style={{
            fontSize: 12,
            lineHeight: 12,
            textAlign: "center",
            marginTop: 5,
            ...subTitleStyle,
          }}
        >
          {subTitle || ""}
        </Text>
      ) : null}
    </TouchableOpacity>
  );
};

const EmptyList = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "5%",
      }}
    >
      <img src={emptyProduct} style={{ width: 120 }} alt={"emptyProduct"} />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        ยังไม่มีรายการ
      </Text>
    </View>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    overflowX: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 1000,
  },
  tableRow: {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: "rgba(255, 255, 255, 1)",
    // },
    // "&:nth-of-type(even)": {
    //   backgroundColor: "#D8DDF8",
    // },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "#4652D1",
    whiteSpace: "nowrap",
    fontSize: 17,
    fontWeight: "bold",
  },
  headerCell2: {
    color: "#FF0000",
    whiteSpace: "nowrap",
    fontSize: 17,
    fontWeight: "bold",
  },
  fingerprintView: { height: "100%", width: 75 },
};

export default TableAttendanceView;
