import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import firebase, {
  auth,
  getRealtimeData,
  signOut,
} from "../../service/firebase";
import history from "../../history/history";
import { Button, Container } from "shards-react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from "react-native-web";
import { useWindowsSize } from "../../function/screenFunction";
import CheeseburgerMenu from "../../component/CheeseburgerMenu";

//Images
import menuIcon from "../../Asset/Images/menu.png";
import employeeIcon from "../../Asset/Images/employee.png";
import documentIcon from "../../Asset/Images/document.png";
import settingIcon from "../../Asset/Images/setting.png";
import reportIcon from "../../Asset/Images/reportGray.png";
import employeeBlueIcon from "../../Asset/Images/employeeBlue.png";
import documentBlueIcon from "../../Asset/Images/documentBlue.png";
import settingBlueIcon from "../../Asset/Images/settingBlue.png";
import reportBlueIcon from "../../Asset/Images/reportBlue.png";
import hrmIcon from "../../Asset/Images/hrmGray.png";
import hrmBlueIcon from "../../Asset/Images/hrmBlue.png";
import bonusIcon from "../../Asset/Images/bonus.png";
import bonusBlueIcon from "../../Asset/Images/bonusBlue.png";
import roleIcon from "../../Asset/Images/role.png";
import roleBlueIcon from "../../Asset/Images/roleBlue.png";
import attendanceIcon from "../../Asset/Images/attendance.png";
import attendanceBlueIcon from "../../Asset/Images/attendanceBlue.png";
import logo from "../../Asset/Images/logo.png";
import { FaUser } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";

//role
import {
  menuEmployeeData,
  menuSlipPaymentData,
  menuReportData,
  menuHrmData,
  menuBonusData,
  menuSettingData,
  menuTimeAttendanceData,
} from "../../service/roleData";
import { useDialog } from "../../component/Modal/Popup";
import { useNavigate } from "react-router-dom";

const HeaderAdmin = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dialog = useDialog();
  const [openMenu, setOpenMenu] = useState(false);
  const size = useWindowsSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pageSelect } = props;
  const { SetPage, Logout } = props;
  const { user } = useSelector((state) => {
    return state;
  });

  //role acction
  const [role, setRole] = useState("");
  const [profile, setProfile] = useState({
    firstname: "",
    lastname: "",
    role: "",
  });
  const [menuEmployee, setMenuEmployee] = useState(false);
  const [menuSlip, setMenuSlip] = useState(false);
  const [menuReport, setMenuReport] = useState(false);
  const [menuHrm, setMenuHrm] = useState(false);
  const [menuBonus, setMenuBonus] = useState(false);
  const [menuSetting, setMenuSetting] = useState(false);
  const [menuAttendance, setMenuAttendance] = useState(false);

  useMemo(() => {
    const downloadRole = async () => {
      const { user } = props;
      if (!user) {
        return;
      }
      if (!user.uid) return;

      try {
        const result = await getRealtimeData(`User/${user.uid}`);
        if (result.status) {
          const { useRole, role } = result.data;
          setProfile(result.data);
          if (useRole) {
            let checkEmployee = false;
            let checkSlip = false;
            let checkReport = false;
            let checkHrm = false;
            let checkBonus = false;
            let checkSetting = false;
            let checkAttendance = false;

            useRole.forEach((item) => {
              if (!checkEmployee) {
                checkEmployee = menuEmployeeData.some((d) => d.value === item);
              }
              if (!checkSlip) {
                checkSlip = menuSlipPaymentData.some((d) => d.value === item);
              }
              if (!checkReport) {
                checkReport = menuReportData.some((d) => d.value === item);
              }
              if (!checkHrm) {
                checkHrm = menuHrmData.some((d) => d.value === item);
              }
              if (!checkBonus) {
                checkBonus = menuBonusData.some((d) => d.value === item);
              }
              if (!checkSetting) {
                checkSetting = menuSettingData.some((d) => d.value === item);
              }
              if (!checkAttendance) {
                checkAttendance = menuTimeAttendanceData.some(
                  (d) => d.value === item
                );
              }
            });

            setMenuEmployee(checkEmployee);
            setMenuSlip(checkSlip);
            setMenuReport(checkReport);
            setMenuHrm(checkHrm);
            setMenuBonus(checkBonus);
            setMenuSetting(checkSetting);
            setMenuAttendance(checkAttendance);
          }
          setRole(role);
        }
      } catch (error) {
        alert(error.message);
      }
    };

    downloadRole();
  }, [props]);

  const handlePage = (id) => {
    console.log("handlePage", id);
    SetPage(id);
    setOpenMenu(false);
    navigate("/");
  };

  const logout = async () => {
    dispatch({ type: "Logout" });
    await signOut(auth);
    navigate("/login");
  };

  return (
    <React.Fragment>
      {user ? (
        <React.Fragment>
          <CheeseburgerMenu
            isOpen={openMenu}
            closeCallback={() => {
              setOpenMenu(false);
            }}
            backgroundColor={"#FFFFFF"}
            width={250}
          >
            <ScrollView style={{ width: "100%", height: "100%" }}>
              <View
                style={{
                  width: "100%",
                  height: size.height,
                  alignItems: "center",
                  paddingRight: 0,
                }}
              >
                {role === "admin" || menuEmployee ? (
                  <React.Fragment>
                    <View style={Styles.spaceMenu} />
                    <MenuButton
                      id={"employee"}
                      isSelect={pageSelect === "employee"}
                      icon={employeeIcon}
                      iconSelect={employeeBlueIcon}
                      title={"งานทะเบียนข้อมูล"}
                      onPress={(id) => {
                        handlePage(id);
                      }}
                    />
                  </React.Fragment>
                ) : null}

                {role === "admin" || menuAttendance ? (
                  <React.Fragment>
                    <View style={Styles.spaceMenu} />
                    <MenuButton
                      id={"attendance"}
                      isSelect={pageSelect === "attendance"}
                      icon={attendanceIcon}
                      iconSelect={attendanceIcon}
                      title={"งานบันทึกเวลา"}
                      onPress={(id) => {
                        handlePage(id);
                      }}
                    />
                  </React.Fragment>
                ) : null}

                {role === "admin" || menuSlip ? (
                  <React.Fragment>
                    <View style={Styles.spaceMenu} />
                    <MenuButton
                      id={"slippayment"}
                      isSelect={pageSelect === "slippayment"}
                      icon={documentIcon}
                      iconSelect={documentBlueIcon}
                      title={"ข้อมูลสลิปเงินเดือน"}
                      onPress={(id) => {
                        handlePage(id);
                      }}
                    />
                  </React.Fragment>
                ) : null}

                {role === "admin" || menuReport ? (
                  <React.Fragment>
                    <View style={Styles.spaceMenu} />
                    <MenuButton
                      id={"report"}
                      isSelect={pageSelect === "report"}
                      icon={reportIcon}
                      iconSelect={reportBlueIcon}
                      title={"รายงาน"}
                      onPress={(id) => {
                        handlePage(id);
                      }}
                    />
                  </React.Fragment>
                ) : null}

                {role === "admin" || menuHrm ? (
                  <React.Fragment>
                    <View style={Styles.spaceMenu} />
                    <MenuButton
                      id={"hrm"}
                      isSelect={pageSelect === "hrm"}
                      icon={hrmIcon}
                      iconSelect={hrmBlueIcon}
                      title={"งาน HRM"}
                      onPress={(id) => {
                        handlePage(id);
                      }}
                    />
                  </React.Fragment>
                ) : null}

                {role === "admin" || menuBonus ? (
                  <React.Fragment>
                    <View style={Styles.spaceMenu} />
                    <MenuButton
                      id={"bonus"}
                      isSelect={pageSelect === "bonus"}
                      icon={bonusIcon}
                      iconSelect={bonusBlueIcon}
                      title={"งานปรับค่าจ้างและโบนัส"}
                      onPress={(id) => {
                        handlePage(id);
                      }}
                    />
                  </React.Fragment>
                ) : null}

                {role === "admin" ? (
                  <React.Fragment>
                    <View style={Styles.spaceMenu} />
                    <MenuButton
                      id={"role"}
                      isSelect={pageSelect === "role"}
                      icon={roleIcon}
                      iconSelect={roleBlueIcon}
                      title={"สิทธิการใช้งาน"}
                      onPress={(id) => {
                        handlePage(id);
                      }}
                    />
                  </React.Fragment>
                ) : null}

                {role === "admin" || menuSetting ? (
                  <React.Fragment>
                    <View style={Styles.spaceMenu} />
                    <MenuButton
                      id={"setting"}
                      isSelect={pageSelect === "setting"}
                      icon={settingIcon}
                      iconSelect={settingBlueIcon}
                      title={"ตั้งค่าข้อมูล"}
                      onPress={(id) => {
                        handlePage(id);
                      }}
                    />
                  </React.Fragment>
                ) : null}

                <TouchableOpacity
                  style={{
                    width: "80%",
                    backgroundColor: "#666666",
                    padding: 10,
                    bottom: 0,
                    position: "absolute",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                    marginBottom: 20,
                  }}
                  onPress={async () => {
                    logout();
                  }}
                >
                  <Text style={{ fontSize: 16, color: "#FFF" }}>
                    ออกจากระบบ
                  </Text>
                </TouchableOpacity>
              </View>
            </ScrollView>
          </CheeseburgerMenu>
          {(profile.role === "admin" || profile.role === "hr") && (
            <View style={{ width: "100vw", backgroundColor: "#EBEBEB" }}>
              <Container>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    height: 50,
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 5,
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "rgba(0,0,0,0)",
                      borderWidth: 0,
                      outline: 0,
                      width: 50,
                    }}
                    onClick={() => {
                      setOpenMenu(true);
                    }}
                  >
                    <img src={menuIcon} alt={"menu"} style={{ width: 25 }} />
                  </Button>
                  <View style={{ flex: 1 }} />
                  {/* <img src={logo} alt={"logo"} style={{ width: 25, height: 25 }} />
            <View style={{ flex: 1 }} /> */}

                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <FaUser
                      style={{
                        fontSize: 20,
                        color: "#333333",
                        marginRight: 12,
                      }}
                    />
                    <View>
                      <Text
                        style={{ fontSize: 14, color: "#333333" }}
                      >{`${profile.firstname} ${profile.lastname}`}</Text>
                      <Text
                        style={{ fontSize: 12, color: "#666666" }}
                      >{`Role : ${
                        profile.role === "admin" ? "ADMIN" : "HR"
                      }`}</Text>
                    </View>
                    <TouchableOpacity
                      style={{ marginLeft: 10 }}
                      onPress={async () => {
                        const confirm = await dialog.confirm({
                          title: `ออกจากระบบ`,
                          message: `คุณต้องการออกจากระบบใช่หรือไม่`,
                        });
                        if (!confirm) {
                          return;
                        }
                        logout();
                      }}
                    >
                      <IoLogOut style={{ fontSize: 25, color: "#FF0000" }} />
                    </TouchableOpacity>
                  </View>
                </View>
              </Container>
            </View>
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

function MenuButton(props) {
  const { id, icon, iconSelect, title, isSelect } = props;
  const { onPress } = props;
  const [hover, setHover] = useState(false);
  return (
    <TouchableOpacity
      style={{
        width: "90%",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: hover || isSelect ? "#EBEBEB" : "",
        padding: 15,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        marginLeft: "10%",
      }}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseOut={() => {
        setHover(false);
      }}
      onPress={() => {
        onPress && onPress(id);
      }}
    >
      <img src={isSelect ? iconSelect : icon} style={{ width: 25 }} />
      <Text
        style={{
          fontSize: 16,
          color: isSelect ? "#4652D1" : "#333",
          fontWeight: "bold",
          marginLeft: 15,
        }}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
}

const Styles = {
  spaceMenu: {
    height: 15,
  },
};

function mapStateToProps(state) {
  return {
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    SetPage: (page) => {
      dispatch({ type: "SetPage", payload: page });
    },
    Logout: async () => {
      // const navigate = useNavigate();
      // navigate("/login");
      // dispatch({ type: "Logout" });
      // await signOut(auth);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAdmin);
