import React from "react";
import { View, Text, Modal, StyleSheet } from "react-native-web";
import Button from "../../component/Button/Button";
import { Animated } from "react-animated-css";

const PopupMessage = (props) => {
  const { title, message, topIcon, style, forceOpen = false } = props;
  const { onConfirm } = props;

  React.useEffect(() => {}, [props]);

  return (
    <Modal transparent={true} visible={true}>
      <View style={Styles.modalContainer}>
        <Animated
          style={Styles.boxBackground}
          animationIn="fadeIn"
          animationInDuration={200}
          isVisible={true}
        >
          <View style={{ ...Styles.boxPopup }}>
            <Animated
              animationIn="bounceIn"
              animationInDuration={400}
              isVisible={true}
            >
              <View style={{ ...Styles.box, ...style }}>
                {topIcon ? topIcon : null}
                <Text style={Styles.textTitle}>
                  {title ? title : "แจ้งเตือน"}
                </Text>
                <View style={{ height: 8 }} />
                <Text style={Styles.textDesc}>{message ? message : ""}</Text>
                {!forceOpen ? (
                  <Button
                    buttonText={"ยืนยัน"}
                    color={"primary"}
                    style={Styles.button}
                    onClick={() => {
                      onConfirm && onConfirm();
                    }}
                  />
                ) : null}
              </View>
            </Animated>
          </View>
        </Animated>
      </View>
    </Modal>
  );
};

const Styles = {
  modalContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999999,
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    padding: 40,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
    marginLeft: "clamp(20px, 2vw, 40px)",
    marginRight: "clamp(20px, 2vw, 40px)",
  },
  boxBackground: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  boxPopup: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#0F1011",
  },
  textDesc: {
    fontSize: 15,
    color: "#696D79",
  },
  button: {
    width: "100%",
    marginTop: 20,
  },
};

export default PopupMessage;
