import React from "react";
import { Modal, Text, View } from "react-native-web";
import { Animated } from "react-animated-css";
import Button from "../Button/Button";
import { Divider } from "rsuite";

const PopupBackdrop = (props) => {
  const { headerTitle = "" } = props;
  const { children } = props;
  const { onClose } = props;
  return (
    <Modal transparent={true} visible={true}>
      <View style={Styles.modalContainer}>
        <Animated
          style={Styles.boxBackground}
          animationIn="fadeIn"
          animationInDuration={200}
          isVisible={true}
        >
          <View style={{ ...Styles.boxPopup }}>
            <Animated
              animationIn="bounceIn"
              animationInDuration={400}
              isVisible={true}
            >
              {headerTitle ? (
                <View style={Styles.container}>
                  <Header
                    title={headerTitle}
                    onClose={() => {
                      if (onClose) {
                        onClose();
                      }
                    }}
                  />
                  {children && children}
                </View>
              ) : (
                children && children
              )}
            </Animated>
          </View>
        </Animated>
      </View>
    </Modal>
  );
};

const Header = (props) => {
  const { title } = props;
  const { onClose, onEnter } = props;
  const { buttons } = props;
  return (
    <View style={{ width: "100%" }}>
      <View style={{ flexDirection: "row" }}>
        <Text style={Styles.title}>{title ? title : ""}</Text>
        {buttons && buttons}
        <Button
          style={{ width: 100 }}
          buttonText={"ปิด"}
          color={"secondary"}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
      </View>
      <Divider />
    </View>
  );
};

const Styles = {
  modalContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 9999,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  box: {
    padding: 40,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
    marginLeft: "clamp(20px, 2vw, 40px)",
    marginRight: "clamp(20px, 2vw, 40px)",
  },
  boxBackground: {
    width: "100%",
    height: "100%",
  },
  boxPopup: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  textTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#0F1011",
  },
  textDesc: {
    fontSize: 15,
    color: "#696D79",
  },
  button: {
    width: "100%",
    marginTop: 20,
  },
  containerButton: {
    flexDirection: "row",
  },
  container: {
    width: "90vw",
    height: "90vh",
    maxWidth: 1200,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
    zIndex: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    flex: 1,
  },
};

export default PopupBackdrop;
