import React, { useState, useEffect } from "react";
import { TouchableOpacity, Text, View } from "react-native-web";
import TimePickerContainer from "./TimePickerContainer";
//Icons
import { BiTime } from "react-icons/bi";
import TextField from "../Textfield/TextField";

const TimePicker = (props) => {
  const { placeholder = "00:00", styles, time, disable } = props;
  const { onChange } = props;
  const [currentHour, setCurrentHour] = useState();
  const [currentMinute, setCurrentMinute] = useState();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [currentValue, setCurrentValue] = useState("");

  useEffect(() => {
    if (time) {
      const _time = time.split(":");
      if (_time.length === 2) {
        const newHour = Number(_time[0]);
        const newMinute = Number(_time[1]);
        // Only update if the hour or minute has changed
        if (
          Number(newHour) !== Number(currentHour) ||
          Number(newMinute) !== Number(currentMinute)
        ) {
          setCurrentHour(newHour);
          setCurrentMinute(newMinute);
          const val = displayTime(newHour, newMinute);
          setCurrentValue(val);
        }
      }
    }
  }, [time]);

  useEffect(() => {
    if (currentValue?.length === 5 && currentValue !== time) {
      const [hour, minute] = currentValue.split(":");
      // Avoid triggering onChange unnecessarily
      if (onChange) {
        onChange({ hour: Number(hour), minute: Number(minute) });
      }
    }
  }, [currentValue, time, onChange]);

  const displayTime = (hour = currentHour, minute = currentMinute) => {
    if (hour != null && minute != null) {
      return `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
    }
    return placeholder;
  };

  const handleChangeTime = (value) => {
    let timeValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    if (timeValue.length >= 1) {
      const firstDigit = parseInt(timeValue[0], 10);
      if (firstDigit > 2) {
        timeValue = ""; // Remove first digit if greater than 2
      }
    }

    if (timeValue.length >= 2) {
      const hh = parseInt(timeValue.slice(0, 2), 10);
      if (hh > 23) {
        timeValue = timeValue.slice(0, 1); // Remove excess digits
      }
    }

    if (timeValue.length >= 3) {
      timeValue = timeValue.slice(0, 2) + ":" + timeValue.slice(2, 4);
    }

    const parts = timeValue.split(":");
    if (parts.length === 2 && parts[1].length > 0) {
      const firstMinuteDigit = parseInt(parts[1][0], 10);
      if (firstMinuteDigit > 5) {
        parts[1] = ""; // Remove excess digit
        timeValue = parts[0] + ":";
      }
    }

    if (parts.length === 2) {
      const mm = parseInt(parts[1], 10);
      if (mm > 59) {
        timeValue = parts[0] + ":" + parts[1].slice(0, 1); // Remove excess digits
      }
    }

    if (timeValue.length > 5) {
      timeValue = timeValue.slice(0, 5);
    }

    return timeValue;
  };

  return (
    <React.Fragment>
      <View style={{ zIndex: 9998 }}>
        <View style={{ ...Styles.button, ...styles }}>
          <TextField
            placeholder={placeholder}
            style={{ flex: 1, borderWidth: 1, textAlign: "center" }}
            value={currentValue}
            onChangeText={(value) => {
              const val = handleChangeTime(value);
              setCurrentValue(val);
            }}
          />

          <View style={{ flex: 1 }} />
          <TouchableOpacity
            style={{ position: "absolute", right: 16 }}
            activeOpacity={0.7}
            onPress={() => {
              if (disable) {
                return;
              }
              setIsPopoverOpen(true);
            }}
          >
            <BiTime style={{ fontSize: 20 }} />
          </TouchableOpacity>
        </View>
        {isPopoverOpen ? (
          <View style={Styles.container}>
            <TimePickerContainer
              onSelect={(values) => {
                const { hour, minute } = values;
                setCurrentHour(hour);
                setCurrentMinute(minute);
                setIsPopoverOpen(false);
                setCurrentValue(displayTime(hour, minute));
                if (onChange) {
                  onChange({ hour: hour, minute: minute });
                }
              }}
              onClickOutside={() => {
                setIsPopoverOpen(false);
              }}
            />
          </View>
        ) : null}
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    position: "absolute",
    right: 0,
    top: 60,
    width: "100%",
    maxWidth: 150,
    maxHeight: 320,
    borderRadius: 10,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    zIndex: 99999,
  },
  button: {
    flexDirection: "row",
    width: "100%",
    height: 50,
    // borderWidth: 1,
    // borderColor: "#D5D7DD",
    // borderRadius: 8,
    alignItems: "center",
    padding: 4,
  },
};

export default TimePicker;
