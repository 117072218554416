import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Alert,
} from "react-native-web";
import { connect, useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { FormSelect, Button } from "shards-react";
//Component
//Service
import language from "../../service/language/language";

const Calendar = (props) => {
  const { birthDate } = props;
  const systemLanguage = "TH";
  const [selectBuildType, setSelectBuildType] = useState(0); //ไม่เกี่ยว
  const [currentDate, setCurrentDate] = useState(moment());
  const [num, setNum] = useState(0);
  const { onSelect, onCancle } = props;
  const month = [
    { value: 1, label: "มกราคม" },
    { value: 2, label: "กุมภาพันธ์" },
    { value: 3, label: "มีนาคม" },
    { value: 4, label: "เมษายน" },
    { value: 5, label: "พฤษภาคม" },
    { value: 6, label: "มิถุนายน" },
    { value: 7, label: "กรกฏาคม" },
    { value: 8, label: "สิงหาคม" },
    { value: 9, label: "กันยายน" },
    { value: 10, label: "ตุลาคม" },
    { value: 11, label: "พฤศจิกายน" },
    { value: 12, label: "ธันวาคม" },
  ];
  const refMonth = useRef();
  const refYear = useRef();

  useEffect(() => {
    if (birthDate) {
      setCurrentDate(moment(Number(birthDate)));
    }
  }, [birthDate]);

  return (
    <View
      style={{ width: "100%", alignItems: "center", justifyContent: "center" }}
    >
      <View style={{ flex: 1, flexDirection: "row" }}>
        <FormSelect
          size="lg"
          ref={refMonth}
          value={month[Number(currentDate.format("MM")) - 1].value}
          style={{ flex: 1 }}
          onChange={(e) => {
            let newDate = currentDate.set("month", e.target.value - 1);
            setCurrentDate(newDate);
            setNum(num + 1);
          }}
        >
          {month.map((item) => {
            return <option value={item.value}>{item.label}</option>;
          })}
        </FormSelect>
        <View style={{ width: 10 }} />
        <FormSelect
          size="lg"
          ref={refYear}
          value={currentDate && Number(currentDate.format("YYYY"))}
          style={{ flex: 1 }}
          onChange={(e) => {
            let newDate = currentDate.set("year", Number(e.target.value));
            setCurrentDate(newDate);
            setNum(num + 1);
          }}
        >
          {year().map((item) => {
            return <option value={item.value}>{item.label}</option>;
          })}
        </FormSelect>
      </View>
      {selectBuildType === 0 ? <BuildMonth /> : <BuildYear />}

      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            onSelect && onSelect(currentDate);
          }}
          style={{
            width: "45%",
            height: 42,
            backgroundColor: "#4652D1",
            borderRadius: 50,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 18, fontWeight: "bold", color: "#FFF" }}>
            ยืนยัน
          </Text>
        </TouchableOpacity>
        <View style={{ width: 30 }} />
        <TouchableOpacity
          onPress={() => {
            onCancle && onCancle();
          }}
          style={{
            width: "45%",
            height: 42,
            backgroundColor: "#FFF",
            borderRadius: 50,
            borderWidth: 1,
            borderColor: "#DADADA",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 18, fontWeight: "bold", color: "#7B7C80" }}>
            ยกเลิก
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  function BuildMonth() {
    return (
      <>
        <View style={{ marginBottom: 10 }} />
        {buildDayBoxTitle(props)}
        <BuildDayBox
          callback={(response) => {
            setCurrentDate(currentDate.set("date", response));
            setNum(num + 1);
          }}
        />
      </>
    );
  }

  function BuildYear(props) {
    return (
      <>
        <View
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <YearBox
              title={language.getWord("มกราคม", systemLanguage)}
              income={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              expense={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            />
            <View style={{ width: 10 }} />
            <YearBox
              title={language.getWord("กุมภาพันธ์", systemLanguage)}
              income={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              expense={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            />
            <View style={{ width: 10 }} />
            <YearBox
              title={language.getWord("มีนาคม", systemLanguage)}
              income={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              expense={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            />
            <View style={{ width: 10 }} />
            <YearBox
              title={language.getWord("เมษายน", systemLanguage)}
              income={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              expense={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            />
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <YearBox
              title={language.getWord("พฤษภาคม", systemLanguage)}
              income={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              expense={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            />
            <View style={{ width: 10 }} />
            <YearBox
              title={language.getWord("มิถุนายน", systemLanguage)}
              income={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              expense={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            />
            <View style={{ width: 10 }} />
            <YearBox
              title={language.getWord("กรกฏาคม", systemLanguage)}
              income={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              expense={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            />
            <View style={{ width: 10 }} />
            <YearBox
              title={language.getWord("สิงหาคม", systemLanguage)}
              income={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              expense={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            />
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <YearBox
              title={language.getWord("กันยายน", systemLanguage)}
              income={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              expense={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            />
            <View style={{ width: 10 }} />
            <YearBox
              title={language.getWord("ตุลาคม", systemLanguage)}
              income={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              expense={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            />
            <View style={{ width: 10 }} />
            <YearBox
              title={language.getWord("พฤศจิกายน", systemLanguage)}
              income={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              expense={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            />
            <View style={{ width: 10 }} />
            <YearBox
              title={language.getWord("ธันวาคม", systemLanguage)}
              income={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              expense={Number(0).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            />
          </View>
        </View>
      </>
    );

    function YearBox(props) {
      const { title, income, expense } = props;
      return (
        <TouchableOpacity
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 10,
            backgroundColor: true ? "" : "#EBEBEB",
            borderRadius: 20,
            padding: 10,
          }}
        >
          <Text style={{ fontSize: 18, fontWeight: "bold" }}>{title}</Text>
          <Text style={{ fontSize: 18, color: "#78D4E2" }}>{income}</Text>
          <Text style={{ fontSize: 18, color: "#ED6F47" }}>{expense}</Text>
        </TouchableOpacity>
      );
    }
  }

  function BuildDayBox(props) {
    const { callback } = props;
    const date = moment(currentDate);
    const [selectNumber, setSelectNumber] = useState(
      moment(currentDate).date()
    );
    const startOfMonth = date.startOf("month").format("dddd");
    const endOfMonth = date.endOf("month").format("dddd");
    const totalDaysInMonth = date.daysInMonth();
    const dayInWeek = [
      { index: 0, word: "Sunday" },
      { index: 1, word: "Monday" },
      { index: 2, word: "Tuesday" },
      { index: 3, word: "Wednesday" },
      { index: 4, word: "Thursday" },
      { index: 5, word: "Friday" },
      { index: 6, word: "Saturday" },
    ];

    console.log(startOfMonth);
    let startDayIndex = dayInWeek.filter((d) => d.word === startOfMonth)[0]
      .index;
    let endDayIndex =
      6 - dayInWeek.filter((d) => d.word === endOfMonth)[0].index;
    let totalRow =
      (totalDaysInMonth + Math.abs(startDayIndex) + Math.abs(endDayIndex)) / 7;

    let content = [<View style={{ marginTop: 10 }} />];
    let currentDay = 0;
    for (let x = 1; x <= totalRow; x++) {
      if (x === 1) {
        //first row
        let data = [];
        for (let i = 0; i < 7; i++) {
          data.push(
            i < startDayIndex
              ? null
              : {
                  day: currentDay + 1,
                  deposit: 0,
                  withDrawer: 0,
                  isActive: true,
                }
          );
          currentDay = i < startDayIndex ? currentDay : currentDay + 1;
        }
        content.push(
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data.map((item, index) => {
              return item ? (
                <DayBox
                  selectNumber={selectNumber}
                  day={item.day}
                  deposit={item.deposit}
                  withDrawer={item.withDrawer}
                  isActive={item.isActive}
                  onPress={(response) => {
                    callback && callback(response);
                  }}
                />
              ) : (
                <DayBox />
              );
            })}
          </View>
        );
      } else if (x === totalRow) {
        //last row
        let data = [];
        for (let i = 0; i < 7; i++) {
          data.push(
            currentDay >= totalDaysInMonth
              ? null
              : {
                  day: currentDay + 1,
                  deposit: 0,
                  withDrawer: 0,
                  isActive: true,
                }
          );
          currentDay =
            currentDay >= totalDaysInMonth ? currentDay : currentDay + 1;
        }

        content.push(
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data.map((item, index) => {
              return item ? (
                <DayBox
                  selectNumber={selectNumber}
                  day={item.day}
                  deposit={item.deposit}
                  withDrawer={item.withDrawer}
                  isActive={item.isActive}
                  onPress={(response) => {
                    callback && callback(response);
                  }}
                />
              ) : (
                <DayBox />
              );
            })}
          </View>
        );
      } else {
        //another row
        let data = [];
        for (let i = 0; i < 7; i++) {
          data.push({
            day: currentDay + 1,
            deposit: 0,
            withDrawer: 0,
            isActive: true,
          });
          currentDay += 1;
        }
        content.push(
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data.map((item, index) => {
              return item ? (
                <DayBox
                  selectNumber={selectNumber}
                  day={item.day}
                  deposit={item.deposit}
                  withDrawer={item.withDrawer}
                  isActive={item.isActive}
                  onPress={(response) => {
                    callback && callback(response);
                  }}
                />
              ) : (
                <DayBox />
              );
            })}
          </View>
        );
      }
    }
    return content;
  }

  function year() {
    let years = [];
    const dateStart = moment().add(-100, "y");
    const dateEnd = moment().add(100, "y");
    while (dateEnd.diff(dateStart, "years") >= 0) {
      years.push({
        value: Number(dateStart.format("YYYY")),
        label: Number(dateStart.format("YYYY")),
      });
      dateStart.add(1, "year");
    }
    return years;
  }
};

const buildDayBoxTitle = (props) => {
  const systemLanguage = "TH";
  return (
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <Text style={{ fontSize: 18, fontWeight: "bold", textAlign: "center" }}>
        {language.getWord("อา.", systemLanguage)}
      </Text>
      <Text style={{ fontSize: 18, fontWeight: "bold", textAlign: "center" }}>
        {language.getWord("จ.", systemLanguage)}
      </Text>
      <Text style={{ fontSize: 18, fontWeight: "bold", textAlign: "center" }}>
        {language.getWord("อ.", systemLanguage)}
      </Text>
      <Text style={{ fontSize: 18, fontWeight: "bold", textAlign: "center" }}>
        {language.getWord("พ.", systemLanguage)}
      </Text>
      <Text style={{ fontSize: 18, fontWeight: "bold", textAlign: "center" }}>
        {language.getWord("พฤ.", systemLanguage)}
      </Text>
      <Text style={{ fontSize: 18, fontWeight: "bold", textAlign: "center" }}>
        {language.getWord("ศ.", systemLanguage)}
      </Text>
      <Text style={{ fontSize: 18, fontWeight: "bold", textAlign: "center" }}>
        {language.getWord("ส.", systemLanguage)}
      </Text>
    </View>
  );
};

function mapStateToProps(state) {
  return {
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);

const DayBox = (props) => {
  const [hover, setHover] = useState(false);
  const { day, deposit, withDrawer, isActive, selectNumber } = props;
  const { onPress } = props;
  return (
    <TouchableOpacity
      style={{
        flex: 1,
        height: 40,
        backgroundColor:
          ((hover && isActive) || selectNumber === day) && day !== undefined
            ? "#EBEBEB"
            : "",
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "space-around",
        padding: 4,
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onPress={() => isActive && onPress && onPress(day)}
    >
      <Text style={{ fontSize: 18 }}>{day || ""}</Text>
      <Text style={{ color: "#78D4E2" }}>{deposit || ""}</Text>
      <Text style={{ color: "#ED6F47" }}>{withDrawer || ""}</Text>
    </TouchableOpacity>
  );
};
