import moment from "moment-timezone";

const month = [
  { value: 1, label: "มกราคม" },
  { value: 2, label: "กุมภาพันธ์" },
  { value: 3, label: "มีนาคม" },
  { value: 4, label: "เมษายน" },
  { value: 5, label: "พฤษภาคม" },
  { value: 6, label: "มิถุนายน" },
  { value: 7, label: "กรกฏาคม" },
  { value: 8, label: "สิงหาคม" },
  { value: 9, label: "กันยายน" },
  { value: 10, label: "ตุลาคม" },
  { value: 11, label: "พฤศจิกายน" },
  { value: 12, label: "ธันวาคม" },
];

export const formatNumberComma = (value, decimal) => {
  return Number(String(value).replace(/[^0-9]+/g, "")).toLocaleString(
    undefined,
    { minimumFractionDigits: decimal ? decimal : 0 }
  );
};

export const formatDateToText = (date) => {
  const _currentDate = moment(date, "DD/MM/YYYY");
  //let _d = moment(date).format("DD/MM/YYYY").split("/");
  //console.log("format here",date,_d)
  // _currentDate.setDate(_d[0]);
  //_currentDate.setMonth(Number(_d[1]) - 1);
  //_currentDate.setFullYear(Number(_d[2]) > Number(moment().format("YYYY")) ? Number(_d[2]) - 543 : Number(_d[2]));
  let newDate = moment(_currentDate);
  return `${moment(newDate).format("DD")} ${
    month.filter((d) => {
      return d.value === Number(moment(newDate).format("MM"));
    })[0].label
  } ${Number(moment(newDate).format("YYYY")) + 543}`;
};

export async function convertBlobUrlToFile(blobUrl, fileName) {
  try {
    // Fetch the blob data from the blob URL
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    // Create a new File object from the blob
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  } catch (error) {
    console.error("Error converting blob URL to file:", error);
    throw error;
  }
}
