import React, { useState, useRef } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
//components
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaTrashAlt,
} from "react-icons/fa";
//images
import emptyProduct from "../../../../Asset/Images/emptyProduct.png";
import { convertMinutesToText } from "../functions/action";
import moment from "moment";
import { MdRemoveRedEye } from "react-icons/md";
import PopupViewLeaveDetail from "./PopupViewLeaveDetail";
//functions
const TablePopupImportLeaveView = (props) => {
  const { tableData = [] } = props;
  const { onOption } = props;
  const tableContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  //state
  const [rowRef, setRowRef] = useState(null);

  const handleScrollLeft = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft -= 100;
      setScrollPosition(tableContainerRef.current.scrollLeft);
    }
  };

  const handleScrollRight = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft += 100;
      setScrollPosition(tableContainerRef.current.scrollLeft);
    }
  };

  const getUpdateDate = (data) => {
    let date = data?.transactionDate ? data?.transactionDate.toDate() : "";
    if (data?.updateDate) {
      date = data?.updateDate.toDate();
    }
    return date;
  };

  const transactionProfileRender = (data) => {
    let date = getUpdateDate(data);
    const names = (profile) => {
      if (profile) {
        return `โดย ${profile?.firstname} ${profile?.lastname}`;
      }
    };
    return (
      <View>
        <Text>{date ? moment(date).format("DD/MM/YYYY HH:mm") : "-"}</Text>
        <Text>
          {data?.transactionProfile
            ? data?.updateTransactionProfile
              ? names(data.updateTransactionProfile)
              : names(data.transactionProfile)
            : "-"}
        </Text>
      </View>
    );
  };

  const handleOptions = (option, value) => {
    if (onOption) {
      onOption({ option: option, ...value });
    }
  };

  return (
    <React.Fragment>
      {tableData.length > 0 ? (
        <View style={{ width: "100%", height: "100%" }}>
          <View style={{ flexDirection: "row", marginBottom: 20 }}>
            <TouchableOpacity
              onPress={() => {
                handleScrollLeft();
              }}
            >
              <FaArrowAltCircleLeft style={{ fontSize: 30 }} />
            </TouchableOpacity>
            <View style={{ flex: 1 }} />
            <TouchableOpacity
              onPress={() => {
                handleScrollRight();
              }}
            >
              <FaArrowAltCircleRight style={{ fontSize: 30 }} />
            </TouchableOpacity>
          </View>
          <TableContainer ref={tableContainerRef} sx={styles.tableContainer}>
            <Table sx={styles.table}>
              <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
                <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
                  <TableCell sx={{ ...styles.headerCell }}>หัวข้อ</TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    <View style={{ justifyContent: "center" }}>
                      <Text style={styles.titleHeader}>จำนวนสิทธิที่ใช้</Text>
                      <Text
                        style={{ color: "#333333" }}
                      >{`(วัน | ชั่วโมง:นาที)`}</Text>
                    </View>
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    <Text style={styles.titleHeader}>สถานะ</Text>
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    <Text style={styles.titleHeader}>แก้ไขล่าสุด</Text>
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    <Text style={styles.titleHeader}>ตัวเลือก</Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((row, index) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        ...styles.tableRow,
                      }}
                    >
                      <TableCell sx={styles.tableCell}>
                        <Text style={{ fontSize: 16 }}>{row?.titleLabel}</Text>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <Text>
                          {row?.leaveDayType === "full"
                            ? "เต็มวัน"
                            : `ช่วงเวลา (${row?.startTime} - ${
                                row?.endTime
                              })\n${convertMinutesToText(row?.totalMinute)}`}
                        </Text>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <Text
                          style={{
                            color: row?.id
                              ? row?.status === "Y"
                                ? "#009116"
                                : "#FF0000"
                              : "#0000FC",
                          }}
                        >
                          {row?.id
                            ? row?.status === "Y"
                              ? "ใช้งาน"
                              : row?.isSync
                              ? "ถูกลบ (ยังไม่บันทึก)"
                              : "ถูกลบ"
                            : "ยังไม่บันทึก"}
                        </Text>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <Text>{transactionProfileRender(row)}</Text>
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <OptionButtons
                            title={``}
                            onPress={() => {
                              handleOptions("view", row);
                            }}
                          >
                            <MdRemoveRedEye
                              style={{ fontSize: 20, color: "#4652d1" }}
                            />
                          </OptionButtons>
                          {row?.status === "Y" ? (
                            <React.Fragment>
                              <View style={{ width: 10 }} />
                              <OptionButtons
                                title={``}
                                onPress={() => {
                                  handleOptions("delete", row);
                                }}
                              >
                                <FaTrashAlt
                                  style={{
                                    fontSize: 15,
                                    color: "#FF0000",
                                  }}
                                />
                              </OptionButtons>
                            </React.Fragment>
                          ) : null}
                        </View>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </View>
      ) : (
        <EmptyList />
      )}
    </React.Fragment>
  );
};

const OptionButtons = (props) => {
  const { title } = props;
  const { onPress } = props;
  return (
    <TouchableOpacity
      style={{ justifyContent: "center", alignItems: "center" }}
      onPress={() => {
        onPress && onPress();
      }}
    >
      {props.children}
      <Text
        style={{
          fontSize: 12,
          lineHeight: 12,
          color: "#000000",
          marginTop: 5,
          textAlign: "center",
        }}
      >
        {title || ""}
      </Text>
    </TouchableOpacity>
  );
};

const EmptyList = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "5%",
      }}
    >
      <img src={emptyProduct} style={{ width: 120 }} alt={"emptyProduct"} />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        ไม่มีรายการ
      </Text>
    </View>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    overflowX: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 1000,
  },
  tableRow: {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: "rgba(255, 255, 255, 1)",
    // },
    // "&:nth-of-type(even)": {
    //   backgroundColor: "#D8DDF8",
    // },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "#4652D1",
    whiteSpace: "nowrap",
    fontSize: 17,
    fontWeight: "bold",
    alignItems: "center",
  },
  headerCell2: {
    color: "#FF0000",
    whiteSpace: "nowrap",
    fontSize: 17,
    fontWeight: "bold",
  },
  fingerprintView: { height: "100%", width: 75 },
  titleHeader: {
    fontSize: 17,
    fontWeight: "bold",
    color: "#4652D1",
  },
};

export default TablePopupImportLeaveView;
