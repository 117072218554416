import React, { useState } from "react";
import moment from "moment";
import { TouchableOpacity, Text } from "react-native-web";

const DayBox = (props) => {
  const [hover, setHover] = useState(false);
  const { currentDate, day, selectNumber, isValid } = props;
  const { onPress } = props;

  const is1 = moment(currentDate).set("date", day);
  const is2 = moment();

  return (
    <TouchableOpacity
      style={{
        flex: 1,
        height: 32,
        backgroundColor:
          hover && isValid
            ? selectNumber === day && isValid
              ? "#4652D1"
              : "#CBD2FF"
            : selectNumber === day && isValid
            ? "#4652D1"
            : "",

        borderTopLeftRadius: 32,
        borderTopRightRadius: 32,
        borderBottomLeftRadius: 32,
        borderBottomRightRadius: 32,
        alignItems: "center",
        justifyContent: "space-around",
        margin: 4,
        borderWidth: is1.isSame(is2, "date") ? 1 : 0,
        borderColor: "#D5D7DD",
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onPress={() => {
        if (isValid) {
          onPress && onPress(day);
        }
      }}
    >
      <Text
        style={{
          fontSize: 14,
          color:
            selectNumber === day && isValid
              ? "#FFFFFF"
              : isValid
              ? "#0F1011"
              : "#B9BDC7",
        }}
      >
        {day || ""}
      </Text>
    </TouchableOpacity>
  );
};

export default DayBox;
