import aapLogo from "../../Asset/Images/AAP.jpg";
import aasLogo from "../../Asset/Images/AAS.jpg";
import amdLogo from "../../Asset/Images/AMD.jpg";
import armLogo from "../../Asset/Images/ARM.jpg";
import amgLogo from "../../Asset/Images/AMG.jpg";
import amtLogo from "../../Asset/Images/AMT.png";
import aabLogo from "../../Asset/Images/AAB.png";
import aatLogo from "../../Asset/Images/AAT.png";

export const getLogoEnterprice = (enterprise) => {
  switch (enterprise) {
    case "AAP":
      return aapLogo;
    case "AAS":
      return aasLogo;
    case "AMD":
      return amdLogo;
    case "ARM":
      return armLogo;
    case "AMG":
      return amgLogo;
    case "AMT":
      return amtLogo;
    case "AAB":
      return aabLogo;
    case "AAT":
      return aatLogo;
    default:
      return aapLogo;
  }
};

export const convertToThaiDate = (dateString) => {
  const monthNames = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  let parts = dateString.split("/");
  let day = parts[0];
  let month = monthNames[parseInt(parts[1]) - 1];
  let year = parseInt(parts[2]);

  // ตรวจสอบว่าเป็นปีคริสศักราชหรือปีพุทธศักราช
  const currentYear = new Date().getFullYear();
  if (year < currentYear + 543 - 100) {
    // ตั้งเป็น 100 ปีก่อนปีปัจจุบัน
    year += 543;
  }

  return `${day} ${month} ${year}`;
};

export const convertEnterpriseFullName = (enterprise) => {
  switch (enterprise) {
    case "AAP":
      return "บริษัท อารีมิตร ออโต้เพ้นท์ จำกัด";
    case "AAS":
      return "บริษัท อารีมิตร ออโตเซลส์ จำกัด";
    case "AMD":
      return "บริษัท อารีมิตร มาสด้า จำกัด";
    case "ARM":
      return "บริษัท อารีมิตร ยนตรการ จำกัด";
    case "AMG":
      return "บริษัท อารีมิตร เอ็มจี จำกัด";
    case "AMT":
      return "บริษัท อารีมิตร เกรท วอลล์ จำกัด";
    default:
      return "";
  }
};

//   function checkEnterprise(enterprise, branch) {
//     switch (enterprise) {
//       case "AAP":
//         return "อารีมิตร ออโต้เพนท์";
//       case "AAS":
//         return "อารีมิตร ออโตเซลส์";
//       case "ARM":
//         return "อารีมิตร ยนตรการ";
//       case "AMG":
//         return "อารีมิตร เอ็มจี";
//       case "AMD":
//         return branch === "AMD1 - สาขากาฬสินธุ์"
//           ? "อารีมิตร มาสด้า สาขากาฬสินธ์ุ"
//           : "อารีมิตร มาสด้า สาขามหาสารคาม";
//       case "AMT":
//         return "อารีมิตร GWM";
//     }
//   }
