import React, { useState, useEffect, useMemo, useRef } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native-web";
import moment from "moment";
import { v4 } from "uuid";
//function
import { year } from "../../../function/format/dateFormat";
const BuildYear = (props) => {
  const { date } = props;
  const { onSelect } = props;
  const ref = useRef();

  return (
    <View
      style={{
        width: "100%",
        maxHeight: 360,

        justifyContent: "center",
        alignItems: "center",
        padding: "2%",
      }}
    >
      <ScrollView ref={ref}>
        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
          {year()
            .reverse()
            .map((item, index) => {
              const is1 = moment(date).set("year", Number(item.label));
              const is2 = moment(date);
              const key = "YearBox_" + v4();
              return (
                <YearBox
                  key={key}
                  data={item}
                  isCurrent={is1.isSame(is2, "year")}
                  onPress={(data) => {
                    onSelect && onSelect(data.value);
                  }}
                  onLayout={(event) => {
                    const layout = event.nativeEvent.layout;
                  }}
                />
              );
            })}
        </View>
      </ScrollView>
    </View>
  );
};

const YearBox = (props) => {
  const { data, isCurrent } = props;
  const { onPress, onLayout } = props;
  const [hover, setHover] = useState(false);

  return (
    <TouchableOpacity
      style={{
        ...Styles.button,
        backgroundColor: isCurrent ? "#4652D1" : hover ? "#CBD2FF" : "",
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onPress={() => {
        onPress && onPress(data);
      }}
      onLayout={(event) => {
        onLayout && onLayout(event);
      }}
    >
      <Text style={{ color: isCurrent ? "#FFFFFF" : "#0F1011" }}>
        {data ? data.label : ""}
      </Text>
    </TouchableOpacity>
  );
};

const Styles = {
  button: {
    width: "21%",
    margin: "2%",
    padding: 5,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
  },
};

export default BuildYear;
