import React, { useState, useEffect } from "react";
import { TouchableOpacity, Text, View } from "react-native-web";
import Calendar from "../CalendarV2/Calendar";
import { Grid } from "@mui/material";
//Icons
import calendarIcon from "../../Asset/Images/components/calendar.png";
import moment from "moment-timezone";
import TimePickerContainer from "../time/TimePickerContainer";
import PopupBackdrop from "../Modal/PopupBackDrop";

const DatePicker = (props) => {
  const {
    date,
    placeholder,
    styles,
    disable,
    zIndex = 99999,
    isPopup = false,
  } = props;
  const { showTime = false } = props;
  const { onChange } = props;
  const [currentDate, setCurrentDate] = useState();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (date) {
      setCurrentDate(date);
    }
  }, [date]);

  return (
    <React.Fragment>
      <View style={{ zIndex: zIndex }}>
        <TouchableOpacity
          activeOpacity={disable ? 1 : 0.7}
          onPress={() => {
            if (disable) {
              return;
            }
            setIsPopoverOpen(true);
          }}
          style={{ ...Styles.button, ...styles }}
        >
          <Text
            style={{
              fontSize: 16,
              color: currentDate ? "#0F1011" : "rgba(138, 145, 161, 1)",
            }}
          >
            {currentDate
              ? showTime
                ? moment(currentDate).format("DD/MM/YYYY HH:mm")
                : moment(currentDate).format("DD/MM/YYYY")
              : placeholder}
          </Text>
          <View style={{ flex: 1 }} />
          <img
            src={calendarIcon}
            style={{ width: 20, height: 20 }}
            alt={"calendarIcon"}
          />
        </TouchableOpacity>
        {isPopoverOpen && !isPopup ? (
          <View
            style={{
              ...Styles.container,
              maxWidth: showTime ? 500 : 320,
              maxHeight: showTime ? 700 : 360,
            }}
          >
            <Grid
              container
              spacing={0}
              style={{ borderWidth: 10, borderColor: "#000000" }}
            >
              <Grid item xs={12} lg={showTime ? 8 : 12}>
                <Calendar
                  date={currentDate}
                  onSelect={(date) => {
                    setCurrentDate(date);
                    if (!showTime) {
                      setIsPopoverOpen(false);
                      onChange && onChange(date);
                    }
                  }}
                  onClickOutside={() => {
                    setIsPopoverOpen(false);
                  }}
                />
              </Grid>
              {showTime ? (
                <Grid item xs={12} lg={4}>
                  <View
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: 10,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <TimePickerContainer
                        onSelect={(values) => {
                          const { hour, minute } = values;
                          let newDate = moment(currentDate);
                          newDate.set("hour", hour);
                          newDate.set("minute", minute);
                          setCurrentDate(newDate);
                          setIsPopoverOpen(false);
                          onChange && onChange(newDate);
                        }}
                        onClickOutside={() => {
                          setIsPopoverOpen(false);
                        }}
                      />
                    </View>
                  </View>
                </Grid>
              ) : null}
            </Grid>
          </View>
        ) : isPopoverOpen && isPopup ? (
          <PopupBackdrop>
            <View
              style={{
                width: 500,
                flexDirection: "row",
                backgroundColor: "#FFFFFF",
                borderRadius: 10,
                boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
                overflow: "hidden",
                maxWidth: showTime ? 500 : 320,
                maxHeight: showTime ? 700 : 360,
              }}
            >
              <Grid
                container
                spacing={0}
                style={{ borderWidth: 10, borderColor: "#000000" }}
              >
                <Grid item xs={12} lg={showTime ? 8 : 12}>
                  <Calendar
                    date={currentDate}
                    onSelect={(date) => {
                      setCurrentDate(date);
                      if (!showTime) {
                        setIsPopoverOpen(false);
                        onChange && onChange(date);
                      }
                    }}
                    onClickOutside={() => {
                      setIsPopoverOpen(false);
                    }}
                  />
                </Grid>
                {showTime ? (
                  <Grid item xs={12} lg={4}>
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: 10,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <TimePickerContainer
                          onSelect={(values) => {
                            const { hour, minute } = values;
                            let newDate = moment(currentDate);
                            newDate.set("hour", hour);
                            newDate.set("minute", minute);
                            setCurrentDate(newDate);
                            setIsPopoverOpen(false);
                            onChange && onChange(newDate);
                          }}
                          onClickOutside={() => {
                            setIsPopoverOpen(false);
                          }}
                        />
                      </View>
                    </View>
                  </Grid>
                ) : null}
              </Grid>
            </View>
          </PopupBackdrop>
        ) : null}
      </View>
    </React.Fragment>
  );
};

const Styles = {
  parentContainer: {
    flexDirection: "row",
  },
  container: {
    width: "100%",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    position: "absolute",
    right: 0,
    top: 60,
    overflow: "hidden",
  },
  button: {
    flexDirection: "row",
    width: 300,
    height: 50,
    borderWidth: 1,
    borderColor: "#D5D7DD",
    borderRadius: 8,
    alignItems: "center",
    padding: 16,
  },
};

export default DatePicker;
