import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
// import { Router, Route } from "react-router";
import history from "./history/history";
import { useIdleTimer } from "react-idle-timer";
import {
  auth,
  getRealtimeDataWithQuery,
  removeRealtimeData,
  signOut,
} from "./service/firebase";
import moment from "moment";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
//CSS
import "./Asset/main.scss";
import "rsuite/dist/rsuite.min.css";
//services
import {
  menuEmployeeData,
  menuSlipPaymentData,
  menuReportData,
  menuHrmData,
  menuBonusData,
  menuSettingData,
  menuTimeAttendanceData,
} from "./service/roleData";
import HeaderAdmin from "./areemit-container/HomeAdmin/HeaderAdmin";
import { useDialog } from "./component/Modal/Popup";
import AuthPage from "./component/AuthPage/AuthPage";
import ManageTimeAttendance from "./areemit-container/HomeAdmin/Attendance/ManageTimeAttendace";
import { equalTo, orderByChild } from "firebase/database";
//PAGES
const Home = lazy(() => import("./areemit-container/Home"));
const Login = lazy(() => import("./areemit-container/Login"));
const LoginAdmin = lazy(() => import("./areemit-container/Login/LoginAdmin"));
// const Register = lazy(() => import("./areemit-container/Register"));
const PrintSlipUser = lazy(() =>
  import("./areemit-container/HomeUser/PrintSlipUser")
);
const SlipPaymentScreen = lazy(() =>
  import("./areemit-container/HomeUser/SlipPaymentScreen")
);
const HrmUser = lazy(() => import("./areemit-container/HomeUser/HrmUser"));
const EventUser = lazy(() => import("./areemit-container/HomeUser/EventUser"));
const PageNotFound = lazy(() =>
  import("./areemit-container/Alert/PageNotFound")
);
const PdfGenerate = lazy(() => import("./component/PdfGenerate"));
const PlayGround = lazy(() =>
  import("./areemit-container/Playground/Playground")
);
// const ManageTimeAttendance = lazy(() =>
//   import("./areemit-container/HomeAdmin/Attendance/ManageTimeAttendace")
// );

function App(props) {
  const { pageSelect, user } = props;
  const { SetPage, SetUseRole, SetRole } = props;
  const dialog = useDialog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profile, setProfile] = useState();
  moment.locale("en");

  useMemo(() => {
    // console.log = function () {};
  }, []);

  useEffect(() => {
    const downloadRole = async () => {
      const { user } = props;
      if (!user) {
        return;
      }
      if (user.uid) {
        await checkSession();
        return;
      }
      try {
        const result = await getRealtimeDataWithQuery(`User/${user.uid}`);
        if (result.status) {
          const { useRole, role } = result.data;
          setProfile(result.data);
          SetRole(role);
          SetPage("employee");

          if (useRole) {
            let checkEmployee = false;
            let checkSlip = false;
            let checkReport = false;
            let checkHrm = false;
            let checkBonus = false;
            let checkSetting = false;
            let checkAttendance = false;

            useRole.forEach((item) => {
              if (!checkEmployee) {
                checkEmployee = menuEmployeeData.some((d) => d.value === item);
              }
              if (!checkSlip) {
                checkSlip = menuSlipPaymentData.some((d) => d.value === item);
              }
              if (!checkReport) {
                checkReport = menuReportData.some((d) => d.value === item);
              }
              if (!checkHrm) {
                checkHrm = menuHrmData.some((d) => d.value === item);
              }
              if (!checkBonus) {
                checkBonus = menuBonusData.some((d) => d.value === item);
              }
              if (!checkSetting) {
                checkSetting = menuSettingData.some((d) => d.value === item);
              }
              if (!checkAttendance) {
                checkAttendance = menuTimeAttendanceData.some(
                  (d) => d.value === item
                );
              }
            });

            SetUseRole(useRole);
            if (!pageSelect) {
              SetPage("employee");
            }
            checkRole(
              checkEmployee,
              checkSlip,
              checkReport,
              checkHrm,
              checkBonus,
              checkSetting,
              checkAttendance
            );
          }

          if (role === "admin" && !pageSelect) {
            SetPage("employee");
          }
        }
      } catch (err) {
        alert(err);
        logout();
      }
    };
    // removeLog();

    downloadRole();
  }, []);

  useEffect(() => {
    if (user) {
      const setMinuteDelay = 5;
      const renewInterval = setInterval(
        checkSession,
        setMinuteDelay * 60 * 1000
      );
      return () => clearInterval(renewInterval);
    }
  }, [user]);

  const removeLog = async () => {
    console.log("removelOG");
    const query = [
      orderByChild("userId"),
      equalTo("rPpOin9xYsYjJ3aH3Io3XgjG97C3"),
    ];
    const result = await getRealtimeDataWithQuery(`LoginLog`, query);

    const keys = Object.keys(result.data);
    const remove = keys.map((key) => {
      return removeRealtimeData(`LoginLog/${key}`);
    });
    const resultremove = await Promise.all(remove);
    console.log("Hello", resultremove);
  };

  const checkRole = (
    checkEmployee,
    checkSlip,
    checkReport,
    checkHrm,
    checkBonus,
    checkSetting,
    checkAttendance
  ) => {
    switch (pageSelect) {
      case "employee":
        checkEmployee
          ? SetPage("employee")
          : setRole(
              checkEmployee,
              checkSlip,
              checkReport,
              checkHrm,
              checkBonus,
              checkSetting
            );
        break;
      case "slippayment":
        checkSlip
          ? SetPage("slippayment")
          : setRole(
              checkEmployee,
              checkSlip,
              checkReport,
              checkHrm,
              checkBonus,
              checkSetting
            );
        break;
      case "report":
        checkReport
          ? SetPage("report")
          : setRole(
              checkEmployee,
              checkSlip,
              checkReport,
              checkHrm,
              checkBonus,
              checkSetting
            );
        break;
      case "hrm":
        checkHrm
          ? SetPage("hrm")
          : setRole(
              checkEmployee,
              checkSlip,
              checkReport,
              checkHrm,
              checkBonus,
              checkSetting
            );
        break;
      case "bonus":
        checkBonus
          ? SetPage("bonus")
          : setRole(
              checkEmployee,
              checkSlip,
              checkReport,
              checkHrm,
              checkBonus,
              checkSetting
            );
        break;
      case "setting":
        checkSetting
          ? SetPage("setting")
          : setRole(
              checkEmployee,
              checkSlip,
              checkReport,
              checkHrm,
              checkBonus,
              checkSetting
            );
        break;
      case "attendance":
        checkAttendance
          ? SetPage("attendance")
          : setRole(
              checkEmployee,
              checkSlip,
              checkReport,
              checkHrm,
              checkBonus,
              checkSetting
            );
        break;
      default:
        setRole(
          checkEmployee,
          checkSlip,
          checkReport,
          checkHrm,
          checkBonus,
          checkSetting
        );
    }
  };

  const setRole = (
    checkEmployee,
    checkSlip,
    checkReport,
    checkHrm,
    checkBonus,
    checkSetting,
    checkAttendance
  ) => {
    if (checkEmployee) {
      SetPage("employee");
      return;
    }
    if (checkSlip) {
      SetPage("slippayment");
      return;
    }
    if (checkReport) {
      SetPage("report");
      return;
    }
    if (checkHrm) {
      SetPage("hrm");
      return;
    }
    if (checkBonus) {
      SetPage("bonus");
      return;
    }
    if (checkSetting) {
      SetPage("setting");
      return;
    }
    if (checkAttendance) {
      SetPage("attendance");
      return;
    }
    logout();
  };

  const handleOnIdle = async (event) => {
    //เมื่อ User ไม่ได้ใช้งานหน้าจอเกินกว่าเวลาที่กำหนด
    let pathName = window.location.pathname;
    let paths = ["login"];
    let check = true;
    paths.forEach((item) => {
      if (pathName === "/" + item) {
        check = false;
      }
    });
    if (check) {
      await dialog.alert({
        message: "คุณไม่ได้ใช้หน้าจอเป็นเวลานาน กรุณา Login ใหม่อีกครั้ง",
      });
      logout();
    }
  };

  const handleOnActive = (event) => {
    //เมื่อ User idle แล้วกลับมาใช้งานใหม่
    console.log("Active!!!");
  };

  const handleOnAction = (event) => {};
  const minuteSession = 60;
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * minuteSession,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  const logout = async () => {
    dispatch({ type: "Logout" });
    await signOut(auth);
    navigate("/login");
  };

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <HeaderAdmin />

        <Routes history={history}>
          <Route path="/" element={isLogin() ? <Home /> : <Login />} />
          <Route path="/login" element={isLogin() ? <Home /> : <Login />} />
          <Route
            path="/loginadmin"
            element={isLogin() ? <Home /> : <LoginAdmin />}
          />
          <Route
            path="/printslipuser"
            element={
              <AuthPage roleList={["employee"]} needLogin={true}>
                <PrintSlipUser />
              </AuthPage>
            }
          />
          <Route
            path="/slippaymentscreen"
            element={
              <AuthPage roleList={["employee"]} needLogin={true}>
                <SlipPaymentScreen />
              </AuthPage>
            }
          />
          <Route
            path="/hrmuser"
            element={
              <AuthPage roleList={["employee"]} needLogin={true}>
                <HrmUser />
              </AuthPage>
            }
          />
          <Route
            path="/eventUser"
            element={
              <AuthPage roleList={["employee"]} needLogin={true}>
                <EventUser />
              </AuthPage>
            }
          />
          <Route
            path="/timeattendance"
            element={
              <AuthPage
                roleList={["admin", "hr"]}
                needLogin={true}
                requireRoleListIfNotAdmin={["userManageTimeAttendance"]}
              >
                <ManageTimeAttendance />
              </AuthPage>
            }
          />
          <Route
            path="/pdfgenerate"
            element={
              <AuthPage roleList={["admin", "hr"]} needLogin={true}>
                <PdfGenerate />
              </AuthPage>
            }
          />
          <Route
            path="/playground"
            element={
              <AuthPage roleList={["admin", "hr"]} needLogin={true}>
                <PlayGround />
              </AuthPage>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </>
  );

  function isLogin() {
    const { user } = props;
    if (!user) {
      return false;
    }
    return true;
  }

  async function checkSession() {
    const { user } = props;
    if (!user || !user.uid) {
      return;
    }
    const checkUserSession = async () => {
      try {
        const result = await getRealtimeDataWithQuery(`User/${user.uid}`);
        if (result.status) {
          const recentLogin = result.data.recentLogin;
          const recentDate = moment(recentLogin);
          const nowDate = moment(Date.now());
          const time = moment.duration(nowDate.diff(recentDate)).asMinutes();
          if (Number(time) > 480) {
            await dialog.alert({
              message: "Session หมดอายุกรุณา Login ใหม่อีกครั้ง",
            });
            logout();
            return;
          }
        } else {
          await dialog.alert({
            message: "ไม่พบข้อมูลของท่านในระบบ โปรดเข้าระบบใหม่อีกครั้ง",
          });
          logout();
          return;
        }
      } catch (error) {
        await dialog.alert({ message: error.message });
        logout();
      }
    };

    checkUserSession();
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    SetPage: (page) => {
      dispatch({ type: "SetPage", payload: page });
    },
    SetRole: (role) => {
      dispatch({ type: "SetRole", payload: role });
    },
    SetUseRole: (useRole) => {
      dispatch({ type: "SetUseRole", payload: useRole });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
